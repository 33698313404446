import React from 'react';
import CabinsHero from '../../assets/cabins/CabinsHero.jpg';
import { Text, Image } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HorizontalRule from '../styles/HorizontalRule';
import HorizontalRuleFW from '../styles/HorizontalRuleFW';
import styled from 'styled-components';
import CabinsOne from '../../assets/cabins/CabinsOne.JPG';
import CabinsTwo from '../../assets/cabins/CabinsTwo.JPG';
import CabinsThree from '../../assets/cabins/CabinsThree.JPG';
import CabinsFour from '../../assets/cabins/CabinsFour.JPG';
import CabinsFive from '../../assets/cabins/CabinsFive.JPG';
import CabinsSix from '../../assets/cabins/CabinsSix.JPG';

import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';

const CabinsTopGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 70% 30%;
    grid-template-rows: 1fr min-content;
  }
`;

const CabinsLayeredGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: flex;
  flex-direction: column;
  grid-column: 1/2;
  padding: 1em;

  @media only screen and (min-width: 1000px) {
    grid-column: 2/3;
    grid-row: 1/2;
    padding: 0;
    padding-right: 4em;
    align-self: center;
  }
`;

const CabinsMiddleGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 1em;

  @media only screen and (min-width: 1000px) {
    padding: 4em;
  }
`;

const CabinsImagesGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  padding: 2em 1em 1em 1em;
  align-items: center;
  justify-items: center;

  @media only screen and (min-width: 1000px) {
    width: 90%;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2em;
    gap: 2em;
    margin: 0 auto;
  }
`;

export default function Cabins() {
  return (
    <>
      <CabinsTopGrid>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 2 } }}
          viewport={{ once: true }}>
          <img
            className='left-hero-image'
            src={CabinsHero}
            alt='Teton Valley Lodge dinner area.'
          />
        </motion.div>

        <CabinsLayeredGrid>
          <motion.div
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
            viewport={{ once: true }}>
            <Text
              className='headline'
              css={{
                fontSize: '30px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              Cabins
            </Text>
            <HorizontalRule className='horizontal-small' />
            <Text
              className='paragraph'
              css={{
                fontSize: '20px',
                '@xs': {
                  fontSize: '30px',
                },
              }}>
              Discover serenity at Teton Valley Lodge, where our cozy cabins,
              nestled along the Teton River, create an ideal retreat for fly
              fishing enthusiasts.
            </Text>
          </motion.div>
        </CabinsLayeredGrid>
      </CabinsTopGrid>
      <HorizontalRuleFW />
      <CabinsMiddleGrid>
        <div className='history-middle-section'>
          <Text
            className='headline-centered'
            css={{
              fontSize: '30px',
              '@xs': {
                fontSize: '35px',
              },
            }}>
            Your Cozy Haven for Fly Fishing Retreats
          </Text>
          <HorizontalRule className='hr-centered' />
          <Text className='paragraph'>
            Welcome to Teton Valley Lodge, where our cozy cabins provide the
            perfect retreat for fly fishing enthusiasts. Nestled amidst the
            breathtaking beauty of the Teton River, our cabins offer a tranquil
            and comfortable setting for guests to relax and unwind.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            Each cabin is thoughtfully designed with rustic charm and modern
            amenities. The interiors feature warm wood accents, comfortable
            furnishings, private bathrooms with complimentary toiletries, as
            well as a fully equipped kitchenette or kitchen.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            At Teton Valley Lodge, we strive to provide our guests with an
            unforgettable experience. Our cabins offer a comfortable and
            inviting space to rest and rejuvenate after a day of exploring the
            great outdoors. Whether you're a seasoned angler or a nature lover,
            our cabins are the perfect base for your fly fishing adventure.
          </Text>
        </div>
      </CabinsMiddleGrid>
      <CabinsImagesGrid>
        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CabinsOne}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CabinsTwo}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CabinsThree}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CabinsFour}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CabinsFive}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CabinsSix}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>
      </CabinsImagesGrid>
    </>
  );
}
