import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';
import styled from 'styled-components';
import {
  Button,
  Text,
  Card,
  Col,
  Row,
  Link,
} from '@nextui-org/react';
import HorizontalRule from '../styles/HorizontalRule';
import '../../components/styles/Contact.css';

const ContactGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2em;

  @media only screen and (min-width: 1000px) {
    margin-top: 2em;
  }
`;

const CardsGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 300px;
  gap: 3em;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 2em;
  margin-top: 0.75em;
  justify-content: center;

  @media only screen and (min-width: 700px) {
    grid-template-columns: 300px 300px;
    margin-top: 1em;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 300px 300px 300px;
    margin-top: 1em;
  }
`;

export default function Contact() {
  const TITLE = 'TVL | Contact Us';
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <ContactGrid>
        <div className='container'>
          <Text
            className='headline-centered'
            css={{
              fontSize: '40px',
              '@xs': {
                fontSize: '60px',
              },
            }}>
            Contact Our Friendly Team
          </Text>
          <Text
            className='subheading-centered'
            css={{
              fontSize: '20px',
              '@xs': {
                fontSize: '25px',
              },
            }}>
            Let us know how we can help
          </Text>
          <HorizontalRule className='hr-centered' />
        </div>
      </ContactGrid>
      <CardsGrid>
        <motion.div
          className='card-lift'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Card css={{ w: '300px', h: '200px' }}>
            <Card.Body css={{ p: '1em', backgroundColor: '#ffffff' }}>
              <Text h2 color='#506A63'>
                Call Us
              </Text>
              <Text p color='#888667' css={{ marginTop: '.25em' }}>
                Mon-Sat from 8am to 5pm
              </Text>
            </Card.Body>
            <Card.Footer
              isBlurred
              css={{
                position: 'absolute',
                bgBlur: '#ffffff66',
                borderTop:
                  '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                bottom: 0,
                zIndex: 1,
                p: '1em',
              }}>
              <Row>
                <Col>
                  <Row justify='flex-start'>
                    <Button flat auto css={{ backgroundColor: '#F75A0E' }}>
                      <Link href='tel:+12083542386'>
                        <Text
                          css={{ color: '#FFFFFF' }}
                          size={13}
                          weight='bold'>
                          +1 208-354-2386
                        </Text>
                      </Link>
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </motion.div>

        <motion.div
          className='card-lift'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Card css={{ w: '300px', h: '200px' }}>
            <Card.Body css={{ p: '1em' }}>
              <Text h2 color='#506A63'>
                Contact Support
              </Text>
              <Text p color='#888667' css={{ marginTop: '.25em' }}>
                Email our staff.
              </Text>
            </Card.Body>
            <Card.Footer
              isBlurred
              css={{
                position: 'absolute',
                bgBlur: '#ffffff66',
                borderTop:
                  '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                bottom: 0,
                zIndex: 1,
                p: '1em',
              }}>
              <Row>
                <Col>
                  <Row justify='flex-start'>
                    <Button flat auto css={{ backgroundColor: '#F75A0E' }}>
                      <Link href='mailto:flyfish@tetonvalleylodge.com'>
                        <Text
                          css={{ color: '#FFFFFF' }}
                          size={13}
                          weight='bold'>
                          flyfish@tetonvalleylodge.com
                        </Text>
                      </Link>
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </motion.div>

        <motion.div
          className='card-lift'
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Card css={{ w: '300px', h: '200px' }}>
            <Card.Body css={{ p: '1em' }}>
              <Text h2 color='#506A63'>
                Visit Us
              </Text>
              <Text p color='#888667' css={{ marginTop: '.25em' }}>
                See us in person.
              </Text>
            </Card.Body>
            <Card.Footer
              isBlurred
              css={{
                position: 'absolute',
                bgBlur: '#ffffff66',
                borderTop:
                  '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                bottom: 0,
                zIndex: 1,
                p: '1em',
              }}>
              <Row>
                <Col>
                  <Row justify='flex-start'>
                    <Button flat auto css={{ backgroundColor: '#F75A0E' }}>
                      <Link target='_blank' href='https://www.google.com/maps/dir/43.9703297,-111.6935994/Teton+Valley+Lodge,+3733+Adams+Rd,+Driggs,+ID+83422/@43.8178991,-111.5960492,11z/data=!3m1!4b1!4m20!1m10!3m9!1s0x53539f5109011f91:0x70966bce81bf1995!2sTeton+Valley+Lodge!5m2!4m1!1i2!8m2!3d43.669112!4d-111.168886!16s%2Fg%2F1tgpmpwk!4m8!1m1!4e1!1m5!1m1!1s0x53539f5109011f91:0x70966bce81bf1995!2m2!1d-111.168884!2d43.6690673?entry=ttu'>
                        <Text
                          css={{ color: '#FFFFFF' }}
                          size={13}
                          weight='bold'>
                          View on Maps
                        </Text>
                      </Link>
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card.Footer>
          </Card>
        </motion.div>
      </CardsGrid>
    </>
  );
}
