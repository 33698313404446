import React from 'react';
import { Helmet } from 'react-helmet';
import HeroImageContainer from '../styles/HeroImageContainer';
import { Text, Container, Image, Button } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HeroImage from '../../assets/cast-and-blast/CastBlastHero-web.jpg';
import ScrollWheel from '../ScrollWheel';
import styled from 'styled-components';
import HorizontalRule from '../styles/HorizontalRule';
import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';
import ImageOne from '../../assets/cast-and-blast/CastBlastMiddle-web.jpg';

const CastBlastOne = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;

  @media only screen and (min-width: 1000px) {
    margin: 4em;
  }
`;

const CastBlastTwo = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;
  margin-top: 4em;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    margin: 4em;
    gap: 4em;
  }
`;

const CastBlastDetailsDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  @media only screen and (min-width: 1000px) {
    margin: 0;
  }
`;

export default function CastAndBlast() {
  const TITLE = 'TVL | Cast & Blast';

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 1 } }}
        viewport={{ once: true }}>
        <HeroImageContainer>
          <img
            className='heroImage'
            src={HeroImage}
            alt='Hunter shooting a gun into the sky.'
          />
          <Container className='heroVideoOverlay'>
            <Container
              css={{
                marginLeft: '-.5em',
                marginTop: '14em',
                padding: '0',
                '@xs': {
                  marginLeft: '1.5em',
                  marginTop: '15em',
                },
              }}>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#ffffff',
                    fontSize: '50px',
                    marginTop: '4em',
                    '@xs': {
                      fontSize: '70px',
                      marginTop: '2em',
                    },
                  }}>
                  CAST & BLAST
                </Text>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#F75A0E',
                    fontSize: '30px',
                    '@xs': {
                      fontSize: '40px',
                      width: '500px',
                    },
                  }}>
                  FLY FISHING AND WING SHOOTING
                </Text>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <a
                  rel='noreferrer'
                  className='heroLink'
                  target='_blank'
                  href='https://vimeo.com/525564026?embedded=true&source=vimeo_logo&owner=2904745'>
                  <Button
                    css={{
                      backgroundColor: '#F75A0E',
                      height: '50px',
                      marginTop: '1.5em',
                      borderRadius: '8px',
                    }}>
                    <Text
                      h1
                      css={{
                        color: '#FFFFFF',
                        fontFamily: 'Roboto Condensed',
                        fontSize: '18px',
                      }}>
                      WATCH VIDEO
                    </Text>
                  </Button>
                </a>
              </motion.div>
            </Container>
          </Container>
        </HeroImageContainer>
      </motion.div>
      <div>
        <ScrollWheel />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
        viewport={{ once: true }}>
        <CastBlastOne>
          <Text
            className='headline-black'
            css={{
              fontSize: '20px',
              textAlign: 'center',
              maxWidth: '800px',
              margin: '0 auto',
              '@xs': {
                fontSize: '30px',
              },
            }}>
            Teton Valley Lodge is your go-to for world-class bird hunting! With
            a guide and a sharp English Setter, enjoy challenging shooting in
            the mountain canyons amidst fall colors and fresh air. Come October,
            they also offer outstanding duck hunting with private blinds on the
            Teton River. Don't forget your waders and camo! After the morning
            shoot, take your pick of fishing or grouse hunting before the
            evening duck shoot.
          </Text>
        </CastBlastOne>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
        viewport={{ once: true }}>
        <Image
          src={ImageOne}
          css={{
            maxWidth: '100%',
            margin: '2em 0 2em 0',
          }}
        />
      </motion.div>

      <CastBlastTwo>
        <CastBlastDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              '@xs': {
                fontSize: '35px',
              },
            }}>
            Grouse & Pheasant Hunting
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            Starting August 30th, Teton Valley Lodge offers some of the finest
            Ruffed Grouse hunting in the world. Your guide and a sharp English
            Setter will head up the mountain canyons surrounding the Lodge for
            what is arguably the most challenging shooting in the sport of bird
            hunting. The fall colors are magnificent and the fresh mountain air
            is an experience in itself.
          </Text>
        </CastBlastDetailsDiv>

        <CastBlastDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              marginTop: '2em',
              '@xs': {
                fontSize: '35px',
                marginTop: '0',
              },
            }}>
            Duck Hunting
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            We also have outstanding duck hunting. The opener is the first or
            second Saturday of October. With 10 private blinds located on the
            Teton River and adjoining sloughs, we offer a tremendous waterfowl
            opportunity.
          </Text>
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            Waders and camo are a must. After the morning shoot, you may choose
            to go fishing or grouse hunting and then back out for an evening
            duck shoot. We are here to provide you with a first class experience
            full of great memories. Let us know what you would like to do and we
            will do it.
          </Text>
        </CastBlastDetailsDiv>
      </CastBlastTwo>
    </>
  );
}
