import React from 'react';
import { Helmet } from 'react-helmet';
import HeroImageContainer from '../styles/HeroImageContainer';
import { Text, Container } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HeroImage from '../../assets/timeline/timeline-hero.jpg';
import ScrollWheel from '../ScrollWheel';
import styled from 'styled-components';
import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';

const TimelineGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2em;

  @media only screen and (min-width: 1000px) {
    margin-top: 4em;
  }
`;

const TimelineDetailsGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;
  align-items: top;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    margin: 4em;
    align-items: start;
  }
`;

const TimelineInfoBox = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  margin-top: 1em;

  @media only screen and (min-width: 1000px) {
    margin-left: 2em;
  }
`;

export default function Timeline() {
  const TITLE = 'TVL | Timeline';
  return (
    <main style={{ maxWidth: '1800px;' }}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 1 } }}
        viewport={{ once: true }}>
        <HeroImageContainer>
          <img
            className='heroImage'
            src={HeroImage}
            alt='Fisherman on a boat casting.'
          />
          <Container className='heroVideoOverlay'>
            <Container
              css={{
                marginLeft: '-.5em',
                marginTop: '14em',
                padding: '0',
                '@xs': {
                  marginLeft: '1.5em',
                  marginTop: '26em',
                },
              }}>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#ffffff',
                    fontSize: '50px',
                    marginTop: '4em',
                    '@xs': {
                      fontSize: '70px',
                      marginTop: '0',
                    },
                  }}>
                 FISHING TIMELINE
                </Text>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#F75A0E',
                    fontSize: '30px',
                    '@xs': {
                      fontSize: '40px',
                      width: '450px',
                    },
                  }}>
                  SOMETHING FOR EVERYBODY ALL YEAR LONG
                </Text>
              </motion.div>
            </Container>
          </Container>
        </HeroImageContainer>
      </motion.div>
      <ScrollWheel />
      <TimelineGrid>
        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              Winter: December-March
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text
                  className='paragraph'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      gridColumn: '2/3',
                    },
                  }}>
                  Winter is pretty cold and snowy around Teton Valley. This time
                  of year is only for the die-hards. Streamers are our favorite
                  way to target fish this time of year. Nymphing can be
                  productive as well. The Teton is mostly frozen over and
                  unfishable along with the upper Henrys Fork. Below Ashton and
                  the South Fork are the only games in town.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              April
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  Winter lets go of her grasp in April. There is still plenty of
                  snow in the hills and even some in the valley, but the days
                  are longer and the feeling of spring is in the air. Sandhill
                  cranes and songbirds start to show up and the fishing gets
                  good!
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  The South Fork will start to have an increase in flows which
                  gets bugs and the fish moving. Worms, Stonefly Nymphs, and
                  lots of other insects are on the menu. The fish are hungry and
                  ready for winter to be over. Streamers are also very
                  productive this time of year. I would dare say that spring
                  streamer fishing is better than fall streamer fishing. Which
                  is contrary to the common belief. The water can be a bit
                  off-color at times which is actually to the benefit of the
                  angler. I love this time of year on the South Fork.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  On the Henrys Fork, the fishing gets hot as well. The water is
                  clear and the fish are hungry. The upper river is still
                  inaccessible but below Ashton the fishing gets hot. Blue Wing
                  Olives will start popping in huge numbers and the fish
                  absolutely love them. It is the first really good dry fly
                  fishing of the year. Caddis are also very strong during April.
                  On days you can see clouds of caddis nymphs floating past in
                  the water. The fish gorge on these bugs. And again streamers
                  can be very effective.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              May
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  This is an exciting month. Everything that was happening in
                  April is still going on, only better. More hatches and more
                  water and more good fishing. Worms and Rubber legs on the
                  South Fork are off the Charts. Some seriously big browns can
                  be caught this time of year. And relatively zero pressure on
                  the river. It's awesome.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  On the Henrys Fork the same, with the added bonus of Mother's
                  Day Caddis. This hatch can be huge. Clouds of caddis fill the
                  air and cover the water. Fish will be actively feeding on
                  these bugs as they bounce on the water and entice aggressive
                  dry fly takes. It is super fun. This happens at the begging of
                  May and lasts through the middle of the month.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  Mid-May May the Stone Flys start moving on the Henrys. Big #4
                  and #6 rubber leg stone fly nymphs are top of the menu for
                  every fish in the river for the next couple of weeks. 10’s of
                  thousands of these huge insects will be crawling from the
                  middle of the river toward the banks exposing themselves and
                  making them vulnerable to attack from the big browns we all
                  want to catch. This is BIG FISH TIME! Don't miss it!
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  Memorial Day weekend is typically the start of the Salmon Fly
                  Hatch. This is one of the most exciting events in the world of
                  Fly Fishing, period. Big Bugs, Big Fish, Big Takes, Big
                  Smiles. The stuff dreams are made of. No Joke.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              June
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  Now we are into summer from our perspective. Things happen
                  fast and don’t stop for the next few months. Salmon Flies and
                  Golden Stones take over the Henrys Fork the first two weeks of
                  June. The South Fork increases in flows and the worm hatch is
                  fire for big browns.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  Mid-June brings out the PMD’s and Green Drakes on the Henrys
                  Fork. The past three years this period has produced the best
                  fishing I have ever seen on the Henrys Fork. Massive Green
                  Drake and others may fly hatches that don’t stop. The very
                  best of the best. The South Fork and Teton flow increases
                  through out June which continues to make the water warmer and
                  get the fish moving. Worms and streamers are the name of the
                  game and man is it fun.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  By the last week in June things change on the Teton and South
                  Fork. The clarity improves and bugs start moving more. Fish
                  take up their summer holding spots and all of a sudden the
                  action starts. Yellow Sallies and PMDs make the first
                  emergence followed shortly by Salmon Flies and big Golden
                  Stones.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              May
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  This is an exciting month. Everything that was happening in
                  April is still going on, only better. More hatches and more
                  water and more good fishing. Worms and Rubber legs on the
                  South Fork are off the Charts. Some seriously big browns can
                  be caught this time of year. And relatively zero pressure on
                  the river. It's awesome.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  On the Henrys Fork the same, with the added bonus of Mother's
                  Day Caddis. This hatch can be huge. Clouds of caddis fill the
                  air and cover the water. Fish will be actively feeding on
                  these bugs as they bounce on the water and entice aggressive
                  dry fly takes. It is super fun. This happens at the begging of
                  May and lasts through the middle of the month.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  Mid-May May the Stone Flys start moving on the Henrys. Big #4
                  and #6 rubber leg stone fly nymphs are top of the menu for
                  every fish in the river for the next couple of weeks. 10’s of
                  thousands of these huge insects will be crawling from the
                  middle of the river toward the banks exposing themselves and
                  making them vulnerable to attack from the big browns we all
                  want to catch. This is BIG FISH TIME! Don't miss it!
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  Memorial Day weekend is typically the start of the Salmon Fly
                  Hatch. This is one of the most exciting events in the world of
                  Fly Fishing, period. Big Bugs, Big Fish, Big Takes, Big
                  Smiles. The stuff dreams are made of. No Joke.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              June
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  Now we are into summer from our perspective. Things happen
                  fast and don’t stop for the next few months. Salmon Flies and
                  Golden Stones take over the Henrys Fork the first two weeks of
                  June. The South Fork increases in flows and the worm hatch is
                  fire for big browns.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  Mid-June brings out the PMD’s and Green Drakes on the Henrys
                  Fork. The past three years this period has produced the best
                  fishing I have ever seen on the Henrys Fork. Massive Green
                  Drake and others may fly hatches that don’t stop. The very
                  best of the best. The South Fork and Teton flow increases
                  through out June which continues to make the water warmer and
                  get the fish moving. Worms and streamers are the name of the
                  game and man is it fun.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  By the last week in June things change on the Teton and South
                  Fork. The clarity improves and bugs start moving more. Fish
                  take up their summer holding spots and all of a sudden the
                  action starts. Yellow Sallies and PMDs make the first
                  emergence followed shortly by Salmon Flies and big Golden
                  Stones.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              July
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  From here on out it's Katy bar the door. Hatches out every day
                  on all three rivers and we are in heaven. The options seem
                  endless and fishing is good everywhere. PMD’s on all the
                  rivers, Stone Flies of every kind on the Teton and South Fork.
                  Green Drake on the Teton and South Fork as well. This is prime
                  time.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              August
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  As July wraps up the stone flies taper off and the PMD’s thin
                  out but are still around. The grass starts to turn more yellow
                  than green and the grain fields are turning golden. The water
                  levels come down a little and hoppers take over. All
                  terrestrials for that matter. Cutthroat sipping big foam with
                  your favorite dropper off the back is the name of the game.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  As the month rolls along the mutant Goldens emerge on the
                  South Fork and make for exciting dry fly fishing for
                  aggressive fish. Big Browns are hunting hoppers on the Henrys
                  Fork and Cutties are fat and happy on the Teton.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              September
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  Hoppers continue as well as the mutant Goldens. More fall may
                  fly hatches show up on all the rivers including tricos,
                  mahogany, calibeitis, baetis. Grey drakes get everyone excited
                  on the Teton River. This hatch is magical and should not be
                  missed by any lover of dry flies. Streamers get back in the
                  action and produce some very big healthy fish during
                  September.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  Blue Wings will become very important as the month rolls
                  along, especially if we get a little cool or wet weather. If
                  you see clouds and precipitation on the radar it's on. The
                  crowds on the rivers fade away as kids go back to school and
                  summer comes to an end.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              October
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  With relatively zero pressure on the rivers and still very
                  nice weather, this is an amazing time to explore our area. The
                  fishing is fantastic, the colors of the hillside are beautiful
                  and you have it almost all to yourself.
                </Text>

                <Text
                  className='paragraph'
                  css={{
                    marginTop: '1em',
                    '@xs': {
                      marginTop: '2em',
                    },
                  }}>
                  All three rivers fish well throughout October. Hoppers are
                  still out and strong in the early part of the month. Grey
                  drakes and Blue Wings get the addition of October Caddis for
                  dry fly action and streamers are strong producers of angry
                  brown trout. I love October.
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <TimelineDetailsGrid>
            <Text
              className='headline'
              css={{
                fontSize: '28px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              November
            </Text>
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <TimelineInfoBox>
                <Text className='paragraph'>
                  More of a hardcore month than October for sure, The weather
                  can turn for the worse but the fish don’t care. Dry Flies are
                  done for all intestine purposes. This is the time for meat.
                  Big ugly streamers and getting after some big fish. Not for
                  the faint of heart. This is chuck and duck and hunting for
                  some big boys. Are you up for it?
                </Text>
              </TimelineInfoBox>
            </motion.div>
          </TimelineDetailsGrid>
        </motion.div>
      </TimelineGrid>
    </main>
  );
}
