import React, { useState } from 'react';
import '../components/styles/ScrollToTopButton.css';
import { MdArrowUpward } from 'react-icons/md';

export default function ScrollToTopButton() {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', handleScroll);

  return (
    <>
      {showButton && (
        <button className='scroll-to-top-button' onClick={scrollToTop}>
          <MdArrowUpward fontSize={24}/>
        </button>
      )}
    </>
  );
}
