import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { createPrismicLink } from 'apollo-link-prismic';
import Header from '../src/components/Header';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import TripDetails from './components/pages/TripDetails';
import BookYourTrip from './components/pages/BookYourTrip';
import Teton from './components/pages/rivers/Teton';
import SouthFork from './components/pages/rivers/SouthFork';
import HenrysFork from './components/pages/rivers/HenrysFork';
import Guides from './components/pages/Guides';
import CastAndBlast from './components/pages/CastAndBlast';
import GlobalStyles from './components/styles/GlobalStyles';
import Footer from './components/Footer';
import ScrollToTopButton from './components/ScrollToTopButton';
// import Destinations from './components/pages/Destinations';
// import TierraDelFuego from './components//pages/destinations/TierraDelFuego';
// import Patagonia from './components/pages/destinations/Patagonia';
// import Yucatan from './components/pages/destinations/Yucatan';
// import TheDelphiClub from './components/pages/destinations/TheDelphiClub';
import History from './components/pages/History';
import Gallery from './components/pages/Gallery';
import Timeline from './components/pages/Timeline';
import Cuisine from './components/pages/Cuisine';
import Cabins from './components/pages/Cabins';

const client = new ApolloClient({
  link: createPrismicLink({
    uri: `${process.env.REACT_APP_API_URL}`,
    accessToken: `${process.env.REACT_APP_ACCESS_TOKEN}`,
  }),
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <GlobalStyles />
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/book-now' element={<BookYourTrip />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/trip-details' element={<TripDetails />} />
          <Route path='/teton' element={<Teton />} />
          <Route path='/south-fork' element={<SouthFork />} />
          <Route path='/henrys-fork' element={<HenrysFork />} />
          <Route path='/guides' element={<Guides />} />
          <Route path='/cast-&-blast' element={<CastAndBlast />} />
          <Route path='/history' element={<History />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/timeline' element={<Timeline />} />
          <Route path='/cuisine' element={<Cuisine />} />
          <Route path='/cabins' element={<Cabins />} />
          {/* <Route path='/destinations' element={<Destinations />} />
        <Route path='/patagonia' element={<Patagonia />} />
        <Route path='/the-delphi-club' element={<TheDelphiClub />} />
        <Route path='/tierra-del-fuego' element={<TierraDelFuego />} />
        <Route path='/yucatan' element={<Yucatan />} /> */}
        </Routes>
        <ScrollToTopButton />
        <Footer />
      </Router>
    </ApolloProvider>
  </React.StrictMode>
);
