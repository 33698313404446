import styled from 'styled-components';
import {
  compose,
  color,
  shadow,
  space,
  border,
  typography,
  layout,
} from 'styled-system';

const HorizontalRuleFW = styled.div`
  ${compose(color, shadow, space, border, typography, layout)}

  border: 1px solid #EAEAEA;
  width: 95%;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
`;

export default HorizontalRuleFW;
