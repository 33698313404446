import React from 'react';
import { Helmet } from 'react-helmet';
import HeroImageContainer from '../styles/HeroImageContainer';
import { Text, Container, Image } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HeroImage from '../../assets/meet-our-guides/GuidesHero-web.jpg';
import ScrollWheel from '../ScrollWheel';
import styled from 'styled-components';
import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';

import AdamOrton from '../../assets/meet-our-guides/Adam_Orton.jpg';
import CalebGates from '../../assets/meet-our-guides/Caleb_Gates.jpg'
import ChrisScott from '../../assets/meet-our-guides/Chris_Scott.jpg'
import DavidBerry from '../../assets/meet-our-guides/David_Berry.jpg'
import EthanBarker from '../../assets/meet-our-guides/Ethan_Barker.jpg'
import GrantReynolds from '../../assets/meet-our-guides/Grant_Reynolds.jpg'
import HarrisonMoulton from '../../assets/meet-our-guides/Harrison_Moulton.jpg'
import HunterHeumann from '../../assets/meet-our-guides/Hunter_Heumann.jpg'
import JacobCommers from '../../assets/meet-our-guides/Jacob_Commers.jpg'
import JacobMoulton from '../../assets/meet-our-guides/Jacob_Moulton.jpg'
import JamieBernstein from '../../assets/meet-our-guides/Jamie_Bernstein.jpg'
import KarlinBilcher from '../../assets/meet-our-guides/Karlin_Bilcher.jpg'
import MattEnoch from '../../assets/meet-our-guides/Matt_Enoch.jpg'
import MichaelSanchez from '../../assets/meet-our-guides/Michael_Sanchez.jpg'
import NateBurr from '../../assets/meet-our-guides/Nate_Burr.jpg'
import PatHogan from '../../assets/meet-our-guides/Pat_Hogan.jpg'
import QuinnPlennes from '../../assets/meet-our-guides/Quinn_Plennes.jpg'
import RussellMoris from '../../assets/meet-our-guides/Russell_Morris.jpg'
import SamAllen from '../../assets/meet-our-guides/Sam_Allen.jpg'
import SamGonzalez from '../../assets/meet-our-guides/Sam_Gonzalez.jpg'
import TannerCarlson from '../../assets/meet-our-guides/Tanner_Carlson.jpg'
import TaylorBarlow from '../../assets/meet-our-guides/Taylor_Barlow.jpg'
import TomCleary from '../../assets/meet-our-guides/Tom_Cleary.jpg'
import TomFarwell from '../../assets/meet-our-guides/Tom_Farwell.jpg'
import TomFenger from '../../assets/meet-our-guides/Tom_Fenger.jpg'
import TylanVining from '../../assets/meet-our-guides/Tylan_Vining.jpg'
import TylerFark from '../../assets/meet-our-guides/Tyler_Fark.jpg'
import BrianBerry from '../../assets/meet-our-guides/Brian_Berry.jpg'
import BradCorbin from '../../assets/meet-our-guides/Brad_Corbin.jpg'
import QuinnDilts from '../../assets/meet-our-guides/Quinn_Dilts.jpg'

const GuideGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2em;

  @media only screen and (min-width: 1000px) {
    margin-top: 4em;
  }
`;

const GuideDetailsGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  margin: 2em;
  align-items: top;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    margin: 4em;
    align-items: center;
  }
`;

const GuideInfoBox = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  margin-top: 1em;

  @media only screen and (min-width: 1000px) {
    margin: 2em;
  }
`;

export default function Guides() {
  const TITLE = 'TVL | Meet Our Guides';
  return (
    <main style={{ maxWidth: '1800px;' }}>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 1 } }}
        viewport={{ once: true }}>
        <HeroImageContainer>
          <img
            className='heroImage'
            src={HeroImage}
            alt='Fisherman on the bank.'
          />
          <Container className='heroVideoOverlay'>
            <Container
              css={{
                marginLeft: '-.5em',
                marginTop: '14em',
                padding: '0',
                '@xs': {
                  marginLeft: '1.5em',
                  marginTop: '26em',
                },
              }}>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#ffffff',
                    fontSize: '50px',
                    marginTop: '4em',
                    '@xs': {
                      fontSize: '70px',
                      marginTop: '0',
                    },
                  }}>
                  MEET OUR GUIDES
                </Text>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#F75A0E',
                    fontSize: '30px',
                    '@xs': {
                      fontSize: '40px',
                      width: '450px',
                    },
                  }}>
                  THE BEST ANGLERS CHOOSE THE BEST GUIDES
                </Text>
              </motion.div>
            </Container>
          </Container>
        </HeroImageContainer>
      </motion.div>
      <ScrollWheel />
      <GuideGrid>
        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={BrianBerry}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Brian Berry
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={TomFenger}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Tom Fenger
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={PatHogan}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Patrick Hogan
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={ChrisScott}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Chris Scott
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={TomCleary}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Tom Cleary
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={TomFarwell}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Tom Farwell
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={MichaelSanchez}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Michael Sanchez
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={NateBurr}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Nate Burr
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={TaylorBarlow}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Taylor Barlow
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={TylerFark}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Tyler Fark
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={RussellMoris}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Russell Morris
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={HarrisonMoulton}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Harrison Moulton
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={HunterHeumann}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Hunter Heumann
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={BradCorbin}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Brad Corbin
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={JamieBernstein}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Jamie Bernstein
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={MattEnoch}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Matt Enoch
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={SamGonzalez}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Sam Gonzalez
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={JacobCommers}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Jacob Commer
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={QuinnDilts}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Quinn Dilts
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={AdamOrton}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Adam Orton
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={SamAllen}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Sam Allen
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={EthanBarker}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Ethan Barker
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={TylanVining}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Tylan Vining
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={DavidBerry}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  David Berry
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={KarlinBilcher}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Karlin Bilcher
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={GrantReynolds}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Grant Reynolds
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={QuinnPlennes}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Quinn Plennes
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={JacobMoulton}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Jacob Moulton
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={CalebGates}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Caleb Gates
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <GuideDetailsGrid>
            <Image
              src={TannerCarlson}
              css={{
                maxWidth: '100%',
                '@xs': {
                  maxWidth: '600px',
                },
              }}
            />
            <motion.div
              initial={{ opacity: 0, y: 150 }}
              whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
              viewport={{ once: true }}>
              <GuideInfoBox>
                <Text
                  className='headline'
                  css={{
                    fontSize: '26px',
                    '@xs': {
                      fontSize: '60px',
                    },
                  }}>
                  Tanner Carlson
                </Text>
              </GuideInfoBox>
            </motion.div>
          </GuideDetailsGrid>
        </motion.div>
      </GuideGrid>
    </main>
  );
}
