import { gql } from '@apollo/client';

export const GET_IMAGES = gql`
  {
    allGallery_images {
      edges {
        node {
          main_image
          _meta {
            id
            uid
          }
        }
      }
    }
  }
`;
