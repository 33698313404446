import React, { useState } from 'react';
import { Navbar, Link, Dropdown, Image, Text, Button } from '@nextui-org/react';
import { NavLink } from 'react-router-dom';
import { Layout } from '../components/styles/Layout';
import { icons } from '../assets/Icons';
import Logo from '../assets/tvl_logo.png';
import { collapseItems } from '../data/navData';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import styled from 'styled-components';
import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';
import HorizontalRuleFW from './styles/HorizontalRuleFW';

const DropdownDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1em;
  margin-bottom: 0.5em;
`;

const NavbarSocialDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: flex;
  flex-direction: row;
  gap: 1em;
  justify-content: center;
`;

export default function Header() {
  const [showReservationsItems, setShowReservationsItems] = useState(false);
  const [showLodgingItems, setShowLodgingItems] = useState(false);
  const [showRiverItems, setShowRiverItems] = useState(false);
  const [showAboutItems, setShowAboutItems] = useState(false);

  const reservationsItems = collapseItems.slice(0, 4);
  const lodgingItems = collapseItems.slice(4, 6);
  const riverItems = collapseItems.slice(6, 10);
  const aboutItems = collapseItems.slice(10, 15);
  const remainingItems = collapseItems.slice(16);
  return (
    <Layout>
      <Navbar maxWidth='fluid' disableBlur className='navbar'>
        <Navbar.Toggle
          css={{ position: 'absolute', right: '20px' }}
          showIn='sm'
        />
        <Navbar.Brand
          css={{
            width: '50px',
            position: 'absolute',
            left: '20px',
            '@xs': {
              position: 'relative',
            },
          }}>
          <a href='/'>
            <Image className='headerLogo' src={Logo} alt='TVL Fish Logo' />
          </a>
        </Navbar.Brand>
        <Navbar.Content
          enableCursorHighlight
          activeColor=''
          hideIn='sm'
          variant='underline'>
          {/* <Navbar.Link href='/'>
            <Text css={{ fontWeight: 700, color: '#506A63' }}>
              HOME
            </Text>
          </Navbar.Link> */}
          <Dropdown isBordered>
            <Navbar.Item>
              <Dropdown.Button
                auto
                light
                css={{
                  px: 0,
                  dflex: 'center',
                  svg: { pe: 'none' },
                  fontFamily: 'Roboto Condensed',
                }}
                iconRight={icons.chevron}
                ripple={false}>
                <Text css={{ fontWeight: 700, color: '#506A63' }}>
                  RESERVATIONS
                </Text>
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label='Rivers'
              css={{
                $$dropdownMenuWidth: '400px',
                $$dropdownItemHeight: '60px',
                '& .nextui-dropdown-item': {
                  py: '$4',
                  '& .nextui-dropdown-item-content': {
                    w: '100%',
                    fontWeight: '$semibold',
                  },
                },
              }}>
              <Dropdown.Item
                key='book_now'
                showFullDescription
                description="The research is done and you're ready to book. This is your next step.">
                <NavLink className='clickableArea navLink' to='/book-now'>
                  <Text
                    css={{
                      color: '#F75A0D',
                      margin: '0',
                      padding: '0',
                    }}>
                    Book Now
                  </Text>
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
                key='trip_details'
                showFullDescription
                description='Ready to start researching your next unforgettable trip to Teton Valley Lodge? Click here!'>
                <NavLink className='clickableArea navLink' to='/trip-details'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Trip Details
                  </Text>
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
                key='cast_blast'
                showFullDescription
                description="Teton Valley Pheasant, Grouse and Duck hunting at it's finest.">
                <NavLink className='clickableArea navLink' to='/cast-&-blast'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Cast & Blast
                  </Text>
                </NavLink>
              </Dropdown.Item>
              {/* <Dropdown.Item
                key='travel_destinations'
                showFullDescription
                description="Teton Valley Lodge's destination fishing trips.">
                <NavLink className='clickableArea navLink' to='/destinations'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Travel Destinations
                  </Text>
                </NavLink>
              </Dropdown.Item> */}
              <Dropdown.Item
                key='timeline'
                showFullDescription
                description='Learn more about the timeline for fishing here at Teton Valley Lodge.'>
                <NavLink className='clickableArea navLink' to='/timeline'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Timeline
                  </Text>
                </NavLink>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown isBordered>
            <Navbar.Item>
              <Dropdown.Button
                auto
                light
                css={{
                  px: 0,
                  dflex: 'center',
                  svg: { pe: 'none' },
                  fontFamily: 'Roboto Condensed',
                }}
                iconRight={icons.chevron}
                ripple={false}>
                <Text css={{ fontWeight: 700, color: '#506A63' }}>LODGING</Text>
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label='Rivers'
              css={{
                $$dropdownMenuWidth: '400px',
                $$dropdownItemHeight: '60px',
                '& .nextui-dropdown-item': {
                  py: '$4',
                  '& .nextui-dropdown-item-content': {
                    w: '100%',
                    fontWeight: '$semibold',
                  },
                },
              }}>
              <Dropdown.Item
                key='book_now'
                showFullDescription
                description="First time at TVL? Take a look at where you'll be staying during your trip.">
                <NavLink className='clickableArea navLink' to='/cabins'>
                  <Text
                    css={{
                      color: '#F75A0D',
                      margin: '0',
                      padding: '0',
                    }}>
                    Cabins
                  </Text>
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
                key='trip_details'
                showFullDescription
                description="TVL cuisine is off the charts. Experience a little homestyle cooking one night and high end grilling the next. We know you'll love the food.">
                <NavLink className='clickableArea navLink' to='/cuisine'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Cuisine
                  </Text>
                </NavLink>
              </Dropdown.Item>
              {/* <Dropdown.Item
                key='contact_us'
                showFullDescription
                description='Experience Teton Valley Lodge from anywhere in the world with our virtual tour. See what your trip will look like with a just a click.'>
                <NavLink className='clickableArea navLink' to='/contact'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Virtual Tour
                  </Text>
                </NavLink>
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown isBordered>
            <Navbar.Item>
              <Dropdown.Button
                auto
                light
                css={{
                  px: 0,
                  dflex: 'center',
                  svg: { pe: 'none' },
                  fontFamily: 'Roboto Condensed',
                }}
                iconRight={icons.chevron}
                ripple={false}>
                <Text css={{ fontWeight: 700, color: '#506A63' }}>RIVERS</Text>
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label='Rivers'
              css={{
                $$dropdownMenuWidth: '400px',
                $$dropdownItemHeight: '60px',
                '& .nextui-dropdown-item': {
                  py: '$4',
                  '& .nextui-dropdown-item-content': {
                    w: '100%',
                    fontWeight: '$semibold',
                    color: '#F75A0D',
                  },
                },
              }}>
              <Dropdown.Item
                key='teton_river'
                showFullDescription
                description='The Teton is more than just a river to us, it is part of everything we are.'>
                <NavLink className='clickableArea navLink' to='/teton'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Teton River
                  </Text>
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
                key='south_fork'
                showFullDescription
                description='If you could only fish one river the rest of your life, the South Fork of the Snake would be the river to select.'>
                <NavLink className='clickableArea navLink' to='/south-fork'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    South Fork
                  </Text>
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
                key='henrys_fork'
                showFullDescription
                description='At six thousand feet above sea-level, Henry’s Fork flows out of a spring created by volcanic events more than a millennium ago.'>
                <NavLink className='clickableArea navLink' to='/henrys-fork'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Henry's Fork
                  </Text>
                </NavLink>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Dropdown isBordered>
            <Navbar.Item>
              <Dropdown.Button
                auto
                light
                css={{
                  px: 0,
                  dflex: 'center',
                  svg: { pe: 'none' },
                  fontFamily: 'Roboto Condensed',
                }}
                iconRight={icons.chevron}
                ripple={false}>
                <Text css={{ fontWeight: 700, color: '#506A63' }}>
                  ABOUT US
                </Text>
              </Dropdown.Button>
            </Navbar.Item>
            <Dropdown.Menu
              aria-label='Rivers'
              css={{
                $$dropdownMenuWidth: '400px',
                $$dropdownItemHeight: '60px',
                '& .nextui-dropdown-item': {
                  py: '$4',
                  '& .nextui-dropdown-item-content': {
                    w: '100%',
                    fontWeight: '$semibold',
                    color: '#F75A0D',
                  },
                },
              }}>
              {/* <Dropdown.Item
                key='staff'
                showFullDescription
                description="Our staff are dedicated to making your experience at Teton Valley Lodge one you'll never forget. They work hard so that you can play hard!">
                <NavLink className='clickableArea navLink' to='/teton'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Staff
                  </Text>
                </NavLink>
              </Dropdown.Item> */}
              <Dropdown.Item
                key='guides'
                showFullDescription
                description='We are a fishing lodge, plain and simple. TVL prioritizes the fishing experience by relying on our skilled, dedicated guides.'>
                <NavLink className='clickableArea navLink' to='/guides'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Guides
                  </Text>
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
                key='gallery'
                showFullDescription
                description="Sometimes it's just fun to look at pictures of big fish and winding rivers.">
                <NavLink className='clickableArea navLink' to='/gallery'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Gallery
                  </Text>
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
                key='history'
                showFullDescription
                description='Teton Valley Lodge has a rich history and we are proud of it.'>
                <NavLink className='clickableArea navLink' to='/history'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    History
                  </Text>
                </NavLink>
              </Dropdown.Item>
              <Dropdown.Item
                key='contact_us'
                showFullDescription
                description='Have a question about fishing or just want to say hi? We’d love to hear from you. Drop us a line and we will respond as soon as possible.'>
                <NavLink className='clickableArea navLink' to='/contact'>
                  <Text css={{ color: '#F75A0D', margin: '0', padding: '0' }}>
                    Contact Us
                  </Text>
                </NavLink>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Navbar.Link href='https://letmrundrift-boats.com/'>
            <Text css={{ fontWeight: 700, color: '#506A63' }}>LET M RUN</Text>
          </Navbar.Link>

          <Navbar.Link href='https://shop.tetonvalleylodge.com/'>
            <Text css={{ fontWeight: 700, color: '#506A63' }}>SHOP TVL</Text>
          </Navbar.Link>
        </Navbar.Content>
        <Navbar.Content>
          {/* <Navbar.Link hideIn={'sm'}>
            <SlPhone style={{ paddingRight: '5px', color: '#506A63' }} />
            <Text css={{ fontWeight: 700, color: '#506A63' }}>
              208-354-2386
            </Text>
          </Navbar.Link> */}
          <Navbar.Item>
            <Button
              auto
              as={Link}
              href='/book-now'
              css={{
                backgroundColor: '#F75A0E',
                display: 'none',
                borderRadius: '8px',
                '@sm': {
                  display: 'block',
                },
              }}>
              <Text css={{ fontWeight: 700, color: '#FFFFFF' }}>BOOK NOW</Text>
            </Button>
          </Navbar.Item>
        </Navbar.Content>
        {/* <Navbar.Collapse>
          {collapseItems.map((item, index) => (
            <Navbar.CollapseItem
              key={index}
              css={{
                // Hamburger menu color
                color: '#001C17',
              }}>
              <Link
                color='inherit'
                css={{
                  minWidth: '100%',
                }}
                href={item.link}>
                {item.title}
              </Link>
            </Navbar.CollapseItem>
          ))}
        </Navbar.Collapse> */}

        <Navbar.Collapse>
          {/* Reservations Div */}
          <DropdownDiv
            onClick={() => setShowReservationsItems(!showReservationsItems)}
            css={{
              cursor: 'pointer',
            }}>
            <Text css={{ fontSize: '23px', fontWeight: 'bold' }}>
              Reservations
            </Text>
            <MdKeyboardArrowDown
              css={{
                marginLeft: '8px', // Adjust as needed to add spacing between text and icon
                // Add other styles as needed
              }}
            />
          </DropdownDiv>

          {/* Display Reservations collapseItems if showReservationsItems is true */}
          {showReservationsItems &&
            reservationsItems.map((item, index) => (
              <Navbar.CollapseItem
                key={index}
                css={{
                  // Hamburger menu color
                  color: '#001C17',
                }}>
                <Link
                  color='inherit'
                  css={{
                    minWidth: '100%',
                    marginLeft: '10px',
                  }}
                  href={item.link}>
                  {item.title}
                </Link>
              </Navbar.CollapseItem>
            ))}

          {/* Lodging Div */}
          <DropdownDiv
            onClick={() => setShowLodgingItems(!showLodgingItems)}
            css={{
              cursor: 'pointer',
              // Add other styles as needed
            }}>
            <Text css={{ fontSize: '23px', fontWeight: 'bold' }}>Lodging</Text>
            <MdKeyboardArrowDown
              css={{
                marginLeft: '8px', // Adjust as needed to add spacing between text and icon
                // Add other styles as needed
              }}
            />
          </DropdownDiv>

          {/* Display Lodging collapseItems if showLodgingItems is true */}
          {showLodgingItems &&
            lodgingItems.map((item, index) => (
              <Navbar.CollapseItem
                key={index + 4} // Adjust the key to avoid conflicts
                css={{
                  // Hamburger menu color
                  color: '#001C17',
                }}>
                <Link
                  color='inherit'
                  css={{
                    minWidth: '100%',
                    marginLeft: '10px',
                  }}
                  href={item.link}>
                  {item.title}
                </Link>
              </Navbar.CollapseItem>
            ))}

          {/* Rivers Div */}
          <DropdownDiv
            onClick={() => setShowRiverItems(!showRiverItems)}
            css={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center', // Align text and icon vertically
              // Add other styles as needed
            }}>
            <Text css={{ fontSize: '23px', fontWeight: 'bold' }}>Rivers</Text>
            <MdKeyboardArrowDown
              css={{
                marginLeft: '8px', // Adjust as needed to add spacing between text and icon
                // Add other styles as needed
              }}
            />
          </DropdownDiv>

          {/* Display River collapseItems if showLodgingItems is true */}
          {showRiverItems &&
            riverItems.map((item, index) => (
              <Navbar.CollapseItem
                key={index + 4} // Adjust the key to avoid conflicts
                css={{
                  // Hamburger menu color
                  color: '#001C17',
                }}>
                <Link
                  color='inherit'
                  css={{
                    minWidth: '100%',
                    marginLeft: '10px',
                  }}
                  href={item.link}>
                  {item.title}
                </Link>
              </Navbar.CollapseItem>
            ))}

          {/* About Div */}
          <DropdownDiv
            onClick={() => setShowAboutItems(!showAboutItems)}
            css={{
              cursor: 'pointer',
              // Add other styles as needed
            }}>
            <Text css={{ fontSize: '23px', fontWeight: 'bold' }}>About Us</Text>
            <MdKeyboardArrowDown
              css={{
                marginLeft: '8px', // Adjust as needed to add spacing between text and icon
                // Add other styles as needed
              }}
            />
          </DropdownDiv>

          {/* Display About collapseItems if showAboutItems is true */}
          {showAboutItems &&
            aboutItems.map((item, index) => (
              <Navbar.CollapseItem
                key={index + 4} // Adjust the key to avoid conflicts
                css={{
                  // Hamburger menu color
                  color: '#001C17',
                }}>
                <Link
                  color='inherit'
                  css={{
                    minWidth: '100%',
                    marginLeft: '10px',
                  }}
                  href={item.link}>
                  {item.title}
                </Link>
              </Navbar.CollapseItem>
            ))}

          {/* Display additional items if showReservationsItems and showLodgingItems are false */}
          {remainingItems.map((item, index) => (
            <Navbar.CollapseItem
              key={index + 6} // Adjust the key to avoid conflicts
              css={{
                // Hamburger menu color
                color: '#001C17',
                margin: '0',
                padding: '0',
              }}>
              <Link
                color='inherit'
                css={{
                  minWidth: '100%',
                }}
                href={item.link}>
                <Text css={{ fontSize: '23px', fontWeight: 'bold' }}>
                  {item.title}
                </Text>
              </Link>
            </Navbar.CollapseItem>
          ))}
          <HorizontalRuleFW />
          <NavbarSocialDiv>
            <a
              style={{ color: '#F75A0E' }}
              rel='noreferrer'
              href='https://www.instagram.com/tetonvalleylodge/'
              target='_blank'>
              <FaInstagram fontSize={23} />
            </a>

            <a
              style={{ color: '#F75A0E' }}
              rel='noreferrer'
              href='https://www.facebook.com/tetonvalleylodge/'
              target='_blank'>
              <FaFacebook fontSize={23} />
            </a>
          </NavbarSocialDiv>
        </Navbar.Collapse>
      </Navbar>
    </Layout>
  );
}
