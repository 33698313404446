import React from 'react';
import LightGallery from 'lightgallery/react';
import { useQuery } from '@apollo/client';
import { GET_IMAGES } from '../../GraphQL/Queries';
import { CircularProgress } from 'react-cssfx-loading';
import Flex from '../styles/Flex';

// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

export default function Gallery() {
  const { loading, error, data } = useQuery(GET_IMAGES);

  if (loading)
    return (
      <Flex justifyContent='center' alignItems='center' height='100vh'>
        <CircularProgress color='#506A63' width='80px' height='80px' />
      </Flex>
    );
  if (error) return `Error! ${error.message}`;

  // console.log(data.allGallery_images.edges);

  return (
    <div className='App'>
      <LightGallery
        speed={500}
        plugins={[lgThumbnail, lgZoom]}
        elementClassNames='custom-wrapper-class'>
        {data.allGallery_images.edges
          .slice()
          .reverse()
          .map((image) => (
            <a key={image.node._meta.id} href={image.node.main_image.url}>
              <img
                width='100%'
                alt={image.node.main_image.alt}
                src={image.node.main_image.url}
              />
            </a>
          ))}
      </LightGallery>
    </div>
  );
}
