import ReactWeather, { useOpenWeather } from 'react-open-weather';

export default function Weather() {
  const { data, isLoading, errorMessage } = useOpenWeather({
    key: '2fcc76035c8ea35fae12e45da49246aa',
    lat: '43.724491',
    lon: '-111.111038',
    lang: 'en',
    unit: 'imperial', // values are (metric, standard, imperial)
  });

  const customStyles = {
    fontFamily: 'Roboto Condensed, sans-serif',
    gradientStart: '#506A63',
    gradientMid: '#506A63',
    gradientEnd: '#506A63',
    locationFontColor: '#FFFFFF',
    todayTempFontColor: '#FFFFFF',
    todayDateFontColor: '#EAEAEA',
    todayRangeFontColor: '#EAEAEA',
    todayDescFontColor: '#EAEAEA',
    todayInfoFontColor: '#EAEAEA',
    todayIconColor: '#FFFFFF',
    forecastBackgroundColor: '#EAEAEA',
    forecastSeparatorColor: '#DDD',
    forecastDateColor: '#181617',
    forecastDescColor: '#181617',
    forecastRangeColor: '#181617',
    forecastIconColor: '#F75A0E',
  };

  return (
    <ReactWeather
      theme={customStyles}
      isLoading={isLoading}
      errorMessage={errorMessage}
      data={data}
      lang='en'
      locationLabel='Driggs, Idaho'
      unitsLabels={{ temperature: 'F', windSpeed: 'mph' }}
      showForecast
    />
  );
}
