import React from 'react';
import { Helmet } from 'react-helmet';
import HeroImageContainer from '../../../components/styles/HeroImageContainer';
import { Text, Container, Image } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HeroImage from '../../../assets/teton-river/TetonHero-web.jpg';
import ScrollWheel from '../../ScrollWheel';
import styled from 'styled-components';
import HorizontalRule from '../../styles/HorizontalRule';
import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';
import ImageOne from '../../../assets/teton-river/teton-cropped-web.jpg';

const RiverGridOne = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;

  @media only screen and (min-width: 1000px) {
    margin: 4em;
  }
`;

const RiverGridTwo = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;
  margin-top: 4em;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 4em;
    gap: 4em;
  }
`;

const RiverDetailsDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  @media only screen and (min-width: 1000px) {
    margin: 0;
  }
`;

export default function Teton() {
  const TITLE = 'TVL | Teton River';

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 1 } }}
        viewport={{ once: true }}>
        <HeroImageContainer>
          <img
            className='heroImage'
            src={HeroImage}
            alt='Fisherman on the bank.'
          />
          <Container className='heroVideoOverlay'>
            <Container
              css={{
                marginLeft: '-.5em',
                marginTop: '14em',
                padding: '0',
                '@xs': {
                  marginLeft: '1.5em',
                  marginTop: '26em',
                },
              }}>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#ffffff',
                    fontSize: '50px',
                    marginTop: '4em',
                    '@xs': {
                      fontSize: '70px',
                      marginTop: '0',
                    },
                  }}>
                  TETON RIVER
                </Text>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#F75A0E',
                    fontSize: '30px',
                    '@xs': {
                      fontSize: '40px',
                      width: '450px',
                    },
                  }}>
                  THE RIVER THAT STARTED IT ALL
                </Text>
              </motion.div>
            </Container>
          </Container>
        </HeroImageContainer>
      </motion.div>
      <div>
        <ScrollWheel />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
        viewport={{ once: true }}>
        <RiverGridOne>
          <Text
            className='headline-black'
            css={{
              fontSize: '20px',
              textAlign: 'center',
              maxWidth: '800px',
              margin: '0 auto',
              '@xs': {
                fontSize: '30px',
              },
            }}>
            The Teton is more than just a river to us, it is part of everything
            we are. This is where everything started. Three generations ago, our
            great-grandfather started guiding anglers on the Teton River. Fly
            fishermen from all over the world have visited this one of a kind
            spring creek. They come for the dry fly fishing.
          </Text>
        </RiverGridOne>
      </motion.div>

        <Image
          src={ImageOne}
          css={{
            maxWidth: '100%',
            margin: '2em 0 2em 0',
          }}
        />

      <RiverGridTwo>
        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              '@xs': {
                fontSize: '35px',
              },
            }}>
            Upper Teton - Spring Creek of Your Dreams
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            The Upper Teton and the Narrows have many differences. Two things
            that they have in common are the fish that inhabit the water and
            what they feed on. Cutthroat dominate the river from top to bottom.
            These gorgeous native trout love to feed on dry flies. PMDs,
            Stoneflies, Drakes, Hoppers, and Caddis are found in all sections of
            the Teton. Of all the characteristics of the Teton River, these two
            make it truly amazing.
          </Text>
        </RiverDetailsDiv>

        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              marginTop: '2em',
              '@xs': {
                fontSize: '35px',
                marginTop: '0',
              },
            }}>
            Narrows
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            The Teton flows through the valley and enters a canyon. This canyon
            is known as the Narrows of the Teton. The Narrows is dramatically
            different than the river that flows past the lodge. The canyon is
            very deep and rugged with little access. Rapids, rattlesnakes, and
            rough terrain describe the Narrows character. This area is best
            suited for anglers looking for adventure and seclusion. The Narrows
            is a very unique and special place.
          </Text>
        </RiverDetailsDiv>

        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              marginTop: '2em',
              '@xs': {
                fontSize: '35px',
                marginTop: '0',
              },
            }}>
            Cutthroat and Dry Flies
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            The cutthroat is the most prevalent, in fact, the Teton has one of
            the strongest holds of native cutthroats found in the West. Every
            day on this stream gives its fisherman a chance at a fish of a
            lifetime. Because of these large fish, and the excitement they
            provoke, the lodge has a special contest just for the Upper Teton.
            The largest fish caught in this section will win a free 3 day trip
            for whoever catches it.
          </Text>
        </RiverDetailsDiv>
      </RiverGridTwo>
    </>
  );
}
