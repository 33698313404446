import React from 'react';
import { Helmet } from 'react-helmet';
import HeroImageContainer from '../../styles/HeroImageContainer';
import { Text, Container, Image } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HeroImage from '../../../assets/henrys-river/HenrysHero-web.jpg';
import ScrollWheel from '../../ScrollWheel';
import styled from 'styled-components';
import HorizontalRule from '../../styles/HorizontalRule';
import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';

import ImageOne from '../../../assets/henrys-river/henrys-cropped-web.jpg';

const RiverGridOne = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;

  @media only screen and (min-width: 1000px) {
    margin: 4em;
  }
`;

const RiverGridTwo = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;
  margin-top: 4em;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 4em;
    gap: 4em;
  }
`;

const RiverDetailsDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  @media only screen and (min-width: 1000px) {
    margin: 0;
  }
`;

export default function HenrysFork() {
  const TITLE = "TVL | Henry's Fork";

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 1 } }}
        viewport={{ once: true }}>
        <HeroImageContainer>
          <img
            className='heroImage'
            src={HeroImage}
            alt='Fisherman on the bank.'
          />
          <Container className='heroVideoOverlay'>
            <Container
              css={{
                marginLeft: '-.5em',
                marginTop: '14em',
                padding: '0',
                '@xs': {
                  marginLeft: '1.5em',
                  marginTop: '26em',
                },
              }}>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#ffffff',
                    fontSize: '50px',
                    marginTop: '4em',
                    '@xs': {
                      fontSize: '70px',
                      marginTop: '0',
                    },
                  }}>
                  HENRY'S FORK
                </Text>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#F75A0E',
                    fontSize: '30px',
                    '@xs': {
                      fontSize: '40px',
                      width: '500px',
                    },
                  }}>
                  A WESTERN FLY FISHING EXPERIENCE
                </Text>
              </motion.div>
            </Container>
          </Container>
        </HeroImageContainer>
      </motion.div>
      <div>
        <ScrollWheel />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
        viewport={{ once: true }}>
        <RiverGridOne>
          <Text
            className='headline-black'
            css={{
              fontSize: '20px',
              textAlign: 'center',
              maxWidth: '800px',
              margin: '0 auto',
              '@xs': {
                fontSize: '30px',
              },
            }}>
            So you want a western fly fishing experience. How about wetting your
            line in an ancient volcano that just so happens to be old
            Yellowstone? At six thousand feet above sea-level, Henry’s Fork
            flows out of a spring created by volcanic events more than a
            millennium ago. This historic area was home to battles between Chief
            Joseph’s Nez Perce tribe and United States military troops.
          </Text>
        </RiverGridOne>
      </motion.div>

        <Image
          src={ImageOne}
          css={{
            maxWidth: '100%',
            margin: '2em 0 2em 0',
          }}
        />

      <RiverGridTwo>
        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              '@xs': {
                fontSize: '35px',
              },
            }}>
            Big Rainbows On Dry Fly
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            The fish are rainbow/cutthroat hybrids or pure rainbows on the upper
            river. You add browns to the mix when you fish below Mesa Falls. The
            entire river is dry fly paradise. There is no river in the world
            that has a better reputation for dry fly fishing. Fishermen who want
            the chance at a giant rainbow on a dry fly flock to this river each
            spring when the Salmon Flies begin to hatch. Some of the largest dry
            fly eating trout in the U.S. are found in this river. We have many
            dedicated anglers who have fished with us for 30-40 years that still
            show up every May or June to lay a dry fly over these giants.
          </Text>
        </RiverDetailsDiv>

        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              marginTop: '2em',
              '@xs': {
                fontSize: '35px',
                marginTop: '0',
              },
            }}>
            1st Dry Fly Area Each Year
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            This one of the best times of the year to catch a monster trout on a
            dry fly. This unique fishing all occurs when the salmon flies hatch
            up and down the river beginning in mid-May. This fabulous hatch
            continues through the 15th of June. Along with the salmon flies, a
            surplus of golden stones and mayflies are out as well. All of these
            hatches mean one thing – big fish! In a three day period, TVL
            fishermen landed a 24 inch, 25 inches and 26-inch trout, all on
            different sections of the river, all on dry flies.
          </Text>
        </RiverDetailsDiv>

        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              marginTop: '2em',
              '@xs': {
                fontSize: '35px',
                marginTop: '0',
              },
            }}>
            Dry Fly Fishing Tradition
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            As the season moves forward, we fish the Henry’s less due to the
            outstanding dry fly action on all of the other 20 sections of our
            other rivers. However, there are times during the season that we
            make sure we go back to this great river fishing. August and
            September are top times for dry fly action. Many 5 to 8-pound fish
            have been caught in these months. As well as being famous for large
            fish, there are times when the rainbows just go nuts. The non-stop
            action of the fishing combined with the extraordinary beauty of the
            river makes any day special. If big rainbows on dry flies are what
            you want, we wholeheartedly recommend the Henry’s Fork of the Snake.
          </Text>
        </RiverDetailsDiv>
      </RiverGridTwo>
    </>
  );
}
