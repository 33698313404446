import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import HeroImageContainer from '../styles/HeroImageContainer';
import HeroImage from '../../assets/book-now/BookNowImage-web.jpeg';
import { motion } from 'framer-motion';
import { Text, Container, Card, Col, Row } from '@nextui-org/react';
import ScrollWheel from '../ScrollWheel';
import CardLogo from '../../assets/book-now/100-year-logo.png';
import '../../components/styles/BookYourTrip.css';
import HorizontalRule from '../styles/HorizontalRule';
import OneNightButton from '../booking-buttons/OneNightButton';
import TwoNightButton from '../booking-buttons/TwoNightButton';
import ThreeNightButton from '../booking-buttons/ThreeNightButton';
import FourNightButton from '../booking-buttons/FourNightButton';
import FiveNightButton from '../booking-buttons/FiveNightButton';
import SixNightButton from '../booking-buttons/SixNightButton';
import FullDayButton from '../booking-buttons/FullDayButton';

import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';

const BookNowGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2em;

  @media only screen and (min-width: 1000px) {
    margin-top: 4em;
  }
`;

const CardsGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  gap: 3em;
  margin: 0 auto;
  margin-bottom: 2em;
  margin-top: 0.75em;

  @media only screen and (max-width: 1424px) and (min-width: 975px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 1em;
  }

  @media only screen and (min-width: 1425px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 1em;
    
  }
`;

const CardsGridTwo = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  gap: 3em;
  margin: 0 auto;
  margin-bottom: 3em;
  margin-top: 0.75em;

  @media only screen and (max-width: 1199px) and (min-width: 700px) {
    margin: 0 auto;
    margin-top: 1em;
  }

  @media only screen and (min-width: 1200px) {
    margin: 0 auto;
    margin-top: 1em;
  }
`;

const BookingDetailsGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  gap: 3em;
  width: 100%;
  margin: 0 auto;
  background-color: #eaeaea;
  padding: 2em 0 2em 0;

  @media only screen and (max-width: 1199px) and (min-width: 700px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 4em;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 6em;
  }
`;

export default function BookYourTrip() {
  const TITLE = 'TVL | Book Now';
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 1 } }}
        viewport={{ once: true }}>
        <HeroImageContainer>
          <img
            className='heroImage'
            src={HeroImage}
            alt='Fisherman on the bank.'
          />
          <Container className='heroVideoOverlay'>
            <Container
              css={{
                marginLeft: '-.5em',
                marginTop: '14em',
                padding: '0',
                '@xs': {
                  marginLeft: '1.5em',
                  marginTop: '26em',
                },
              }}>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#ffffff',
                    fontSize: '50px',
                    marginTop: '4em',
                    '@xs': {
                      fontSize: '70px',
                      marginTop: '0',
                    },
                  }}>
                  LET'S GET BOOKING
                </Text>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#F75A0E',
                    fontSize: '30px',
                    '@xs': {
                      fontSize: '40px',
                      width: '600px',
                    },
                  }}>
                  YOUR WESTERN ADVENTURE AWAITS
                </Text>
              </motion.div>
            </Container>
          </Container>
        </HeroImageContainer>
      </motion.div>
      <div>
        <ScrollWheel />
      </div>
      <BookNowGrid>
        <Container>
          <Text
            className='headline'
            css={{
              fontSize: '50px',
              textAlign: 'center',
              '@xs': {
                fontSize: '60px',
              },
            }}>
            Multi-Day Trips
          </Text>
          <HorizontalRule className='hr-centered' />
        </Container>
        <CardsGrid>
          <motion.div
            className='card-lift'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 1 } }}
            viewport={{ once: true }}>
            <Card css={{ w: '320px', h: '350px' }}>
              <Card.Header css={{ position: 'absolute', zIndex: 1, top: 5 }}>
                <Col>
                  <Text
                    size={12}
                    weight='bold'
                    transform='uppercase'
                    color='#ffffffAA'>
                    TVL
                  </Text>
                </Col>
              </Card.Header>
              <Card.Body css={{ p: 0, backgroundColor: '#506A63' }}>
                <Col css={{ marginTop: '2em' }}>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.5em',
                      fontSize: '40px',
                      textAlign: 'center',
                    }}>
                    1 NIGHT 1 DAY
                  </Text>
                  <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '1em',
                      textAlign: 'center',
                    }}>
                    Double Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $1,854
                  </Text>
                  <Text
                    h4
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '.25em',
                      textAlign: 'center',
                    }}>
                    $927 Per Person
                  </Text>
                  {/* <HorizontalRule className='hr-centered' /> */}
                  {/* <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '3em',
                      textAlign: 'center',
                    }}>
                    Single Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $1,740.77
                  </Text> */}
                </Col>
              </Card.Body>
              <Card.Footer
                isBlurred
                css={{
                  position: 'absolute',
                  bgBlur: '#ffffff66',
                  borderTop:
                    '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <Row>
                  <Col className='card-logo-container'>
                    <img
                      src={CardLogo}
                      className='card-logo-booking'
                      alt='Teton Valley Fishing Lodge 100 years logo'
                    />
                  </Col>
                  <Col>
                    <Row justify='flex-end'>
                      <OneNightButton />
                    </Row>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </motion.div>

          <motion.div
            className='card-lift'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 1 } }}
            viewport={{ once: true }}>
            <Card css={{ w: '320px', h: '350px' }}>
              <Card.Header css={{ position: 'absolute', zIndex: 1, top: 5 }}>
                <Col>
                  <Text
                    size={12}
                    weight='bold'
                    transform='uppercase'
                    color='#ffffffAA'>
                    TVL
                  </Text>
                </Col>
              </Card.Header>
              <Card.Body css={{ p: 0, backgroundColor: '#506A63' }}>
                <Col css={{ marginTop: '2em' }}>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.5em',
                      fontSize: '40px',
                      textAlign: 'center',
                    }}>
                    2 NIGHTS 1 DAY
                  </Text>
                  <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '1em',
                      textAlign: 'center',
                    }}>
                    Double Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $2,611
                  </Text>
                  <Text
                    h4
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '.25em',
                      textAlign: 'center',
                    }}>
                    $1,305 Per Person
                  </Text>
                  {/* <HorizontalRule className='hr-centered' /> */}
                  {/* <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '3em',
                      textAlign: 'center',
                    }}>
                    Single Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $2,479.99
                  </Text> */}
                </Col>
              </Card.Body>
              <Card.Footer
                isBlurred
                css={{
                  position: 'absolute',
                  bgBlur: '#ffffff66',
                  borderTop:
                    '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <Row>
                  <Col className='card-logo-container'>
                    <img
                      src={CardLogo}
                      className='card-logo-booking'
                      alt='Teton Valley Fishing Lodge 100 years logo'
                    />
                  </Col>
                  <Col>
                    <Row justify='flex-end'>
                      <TwoNightButton />
                    </Row>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </motion.div>

          <motion.div
            className='card-lift'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 1 } }}
            viewport={{ once: true }}>
            <Card css={{ w: '320px', h: '350px' }}>
              <Card.Header css={{ position: 'absolute', zIndex: 1, top: 5 }}>
                <Col>
                  <Text
                    size={12}
                    weight='bold'
                    transform='uppercase'
                    color='#ffffffAA'>
                    TVL
                  </Text>
                </Col>
              </Card.Header>
              <Card.Body css={{ p: 0, backgroundColor: '#506A63' }}>
                <Col css={{ marginTop: '2em' }}>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.5em',
                      fontSize: '40px',
                      textAlign: 'center',
                    }}>
                    3 NIGHTS 2 DAYS
                  </Text>
                  <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '1em',
                      textAlign: 'center',
                    }}>
                    Double Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $4,493
                  </Text>
                  <Text
                    h4
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '.25em',
                      textAlign: 'center',
                    }}>
                    $2,246 Per Person
                  </Text>
                  {/* <HorizontalRule className='hr-centered' /> */}
                  {/* <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '3em',
                      textAlign: 'center',
                    }}>
                    Single Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $4,251.78
                  </Text> */}
                </Col>
              </Card.Body>
              <Card.Footer
                isBlurred
                css={{
                  position: 'absolute',
                  bgBlur: '#ffffff66',
                  borderTop:
                    '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <Row>
                  <Col className='card-logo-container'>
                    <img
                      src={CardLogo}
                      className='card-logo-booking'
                      alt='Teton Valley Fishing Lodge 100 years logo'
                    />
                  </Col>
                  <Col>
                    <Row justify='flex-end'>
                      <ThreeNightButton />
                    </Row>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </motion.div>

          <motion.div
            className='card-lift'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 1 } }}
            viewport={{ once: true }}>
            <Card css={{ w: '320px', h: '350px' }}>
              <Card.Header css={{ position: 'absolute', zIndex: 1, top: 5 }}>
                <Col>
                  <Text
                    size={12}
                    weight='bold'
                    transform='uppercase'
                    color='#ffffffAA'>
                    TVL
                  </Text>
                </Col>
              </Card.Header>
              <Card.Body css={{ p: 0, backgroundColor: '#506A63' }}>
                <Col css={{ marginTop: '2em' }}>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.5em',
                      fontSize: '40px',
                      textAlign: 'center',
                    }}>
                    4 NIGHTS 3 DAYS
                  </Text>
                  <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '1em',
                      textAlign: 'center',
                    }}>
                    Double Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $6,363
                  </Text>
                  <Text
                    h4
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '.25em',
                      textAlign: 'center',
                    }}>
                    $3,181 Per Person
                  </Text>
                  {/* <HorizontalRule className='hr-centered' /> */}
                  {/* <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '3em',
                      textAlign: 'center',
                    }}>
                    Single Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $6,002.18
                  </Text> */}
                </Col>
              </Card.Body>
              <Card.Footer
                isBlurred
                css={{
                  position: 'absolute',
                  bgBlur: '#ffffff66',
                  borderTop:
                    '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <Row>
                  <Col className='card-logo-container'>
                    <img
                      src={CardLogo}
                      className='card-logo-booking'
                      alt='Teton Valley Fishing Lodge 100 years logo'
                    />
                  </Col>
                  <Col>
                    <Row justify='flex-end'>
                      <FourNightButton />
                    </Row>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </motion.div>

          <motion.div
            className='card-lift'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 1 } }}
            viewport={{ once: true }}>
            <Card css={{ w: '320px', h: '350px' }}>
              <Card.Header css={{ position: 'absolute', zIndex: 1, top: 5 }}>
                <Col>
                  <Text
                    size={12}
                    weight='bold'
                    transform='uppercase'
                    color='#ffffffAA'>
                    TVL
                  </Text>
                </Col>
              </Card.Header>
              <Card.Body css={{ p: 0, backgroundColor: '#506A63' }}>
                <Col css={{ marginTop: '2em' }}>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.5em',
                      fontSize: '40px',
                      textAlign: 'center',
                    }}>
                    5 NIGHTS 4 DAYS
                  </Text>
                  <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '1em',
                      textAlign: 'center',
                    }}>
                    Double Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $8,235
                  </Text>
                  <Text
                    h4
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '.25em',
                      textAlign: 'center',
                    }}>
                    $4,117 Per Person
                  </Text>
                  {/* <HorizontalRule className='hr-centered' /> */}
                  {/* <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '3em',
                      textAlign: 'center',
                    }}>
                    Single Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $7,724.34
                  </Text> */}
                </Col>
              </Card.Body>
              <Card.Footer
                isBlurred
                css={{
                  position: 'absolute',
                  bgBlur: '#ffffff66',
                  borderTop:
                    '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <Row>
                  <Col className='card-logo-container'>
                    <img
                      src={CardLogo}
                      className='card-logo-booking'
                      alt='Teton Valley Fishing Lodge 100 years logo'
                    />
                  </Col>
                  <Col>
                    <Row justify='flex-end'>
                      <FiveNightButton />
                    </Row>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </motion.div>

          <motion.div
            className='card-lift'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 1 } }}
            viewport={{ once: true }}>
            <Card css={{ w: '320px', h: '350px' }}>
              <Card.Header css={{ position: 'absolute', zIndex: 1, top: 5 }}>
                <Col>
                  <Text
                    size={12}
                    weight='bold'
                    transform='uppercase'
                    color='#ffffffAA'>
                    TVL
                  </Text>
                </Col>
              </Card.Header>
              <Card.Body css={{ p: 0, backgroundColor: '#506A63' }}>
                <Col css={{ marginTop: '2em' }}>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.5em',
                      fontSize: '40px',
                      textAlign: 'center',
                    }}>
                    6 NIGHTS 5 DAYS
                  </Text>
                  <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '1em',
                      textAlign: 'center',
                    }}>
                    Double Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $10,112
                  </Text>
                  <Text
                    h4
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '.25em',
                      textAlign: 'center',
                    }}>
                    $5,056 Per Person
                  </Text>
                  {/* <HorizontalRule className='hr-centered' /> */}
                  {/* <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '3em',
                      textAlign: 'center',
                    }}>
                    Single Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '45px',
                      textAlign: 'center',
                    }}>
                    $9,481.06
                  </Text> */}
                </Col>
              </Card.Body>
              <Card.Footer
                isBlurred
                css={{
                  position: 'absolute',
                  bgBlur: '#ffffff66',
                  borderTop:
                    '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <Row>
                  <Col className='card-logo-container'>
                    <img
                      src={CardLogo}
                      className='card-logo-booking'
                      alt='Teton Valley Fishing Lodge 100 years logo'
                    />
                  </Col>
                  <Col>
                    <Row justify='flex-end'>
                      <SixNightButton />
                    </Row>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </motion.div>
        </CardsGrid>
        <Container>
          <Text
            className='headline'
            css={{
              marginTop: '1em',
              fontSize: '55px',
              textAlign: 'center',
              '@xs': {
                fontSize: '60px',
              },
            }}>
            Single-Day Trips
          </Text>
          <HorizontalRule className='hr-centered' />
        </Container>
        <CardsGridTwo>
          <motion.div
            className='card-lift'
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1, transition: { duration: 1 } }}
            viewport={{ once: true }}>
            <Card css={{ w: '320px', h: '420px' }}>
              <Card.Header css={{ position: 'absolute', zIndex: 1, top: 5 }}>
                <Col>
                  <Text
                    size={12}
                    weight='bold'
                    transform='uppercase'
                    color='#ffffffAA'>
                    TVL
                  </Text>
                </Col>
              </Card.Header>
              <Card.Body css={{ p: 0, backgroundColor: '#181617' }}>
                <Col css={{ marginTop: '2em' }}>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      margin: '0 auto',
                      marginTop: '.5em',
                      fontSize: '50px',
                      textAlign: 'center',
                      maxWidth: '300px',
                    }}>
                    FULL DAY OF FLY FISHING
                  </Text>

                  <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '.25em',
                      textAlign: 'center',
                    }}>
                    Double Occupancy
                  </Text>
                  <Text
                    h1
                    color='#FFFFFF'
                    css={{
                      marginTop: '.25em',
                      fontSize: '55px',
                      textAlign: 'center',
                    }}>
                    $780
                  </Text>
                  <Text
                    h3
                    color='#ffffffAA'
                    css={{
                      fontWeight: 'normal',
                      marginTop: '.25em',
                      textAlign: 'center',
                    }}>
                    Per Boat
                  </Text>
                  {/* <Col className='tripDetailsListContainer'>
                    <li className='tripDetailList'>GUIDE SERVICE</li>
                    <li className='tripDetailList'>DRIFT BOAT OR RAFT</li>
                    <li className='tripDetailList'>FLIES & TACKLE</li>
                    <li className='tripDetailList'>3 MEALS A DAY</li>
                    <li className='tripDetailList'>DRINKS & PROVISIONS</li>
                  </Col> */}
                </Col>
              </Card.Body>
              <Card.Footer
                isBlurred
                css={{
                  position: 'absolute',
                  bgBlur: '#ffffff66',
                  borderTop:
                    '$borderWeights$light solid rgba(255, 255, 255, 0.2)',
                  bottom: 0,
                  zIndex: 1,
                }}>
                <Row>
                  <Col className='card-logo-container'>
                    <img
                      src={CardLogo}
                      className='card-logo-booking'
                      alt='Teton Valley Fishing Lodge 100 years logo'
                    />
                  </Col>
                  <Col>
                    <Row justify='flex-end'>
                      {/* <Button
                        flat
                        auto
                        rounded
                        css={{ backgroundColor: '#F75A0E' }}>
                        <Text
                          css={{ color: '#FFFFFF' }}
                          size={12}
                          weight='bold'
                          transform='uppercase'>
                          Book Now
                        </Text>
                      </Button> */}
                      <FullDayButton />
                    </Row>
                  </Col>
                </Row>
              </Card.Footer>
            </Card>
          </motion.div>
        </CardsGridTwo>
        <BookingDetailsGrid>
          <div>
            <Text
              className='headline-centered'
              css={{
                fontSize: '25px',
                textAlign: 'center',
              }}>
              Items Included in Rates
            </Text>
            <HorizontalRule className='hr-centered' />
            <div>
              <Text
                className='paragraph-centered paragraph'
                css={{
                  width: '100%',
                  margin: '0 auto',
                  marginTop: '1em',
                  paddingLeft: '1em',
                  paddingRight: '1em',
                }}>
                All-inclusive rates include meals, lodging, guide service,
                flies, leaders, terminal tackle, transportation to and from
                rivers, soft drinks, beer and wine, and all applicable taxes.
                Our guides carry with them all available tackle and a complete
                selection of our entire inventory including our custom fly
                patterns.
              </Text>
            </div>
          </div>

          <div>
            <Text
              className='headline-centered'
              css={{
                fontSize: '25px',
                textAlign: 'center',
              }}>
              Items Not Included in Rates
            </Text>
            <HorizontalRule className='hr-centered' />
            <div>
              <Text
                className='paragraph-centered paragraph'
                css={{
                  width: '100%',
                  margin: '0 auto',
                  marginTop: '1em',
                  paddingLeft: '1em',
                  paddingRight: '1em',
                }}>
                Items not included in the rates are gratuities for guides and staff, items purchased
                in the tackle shop, fishing licenses, and airport shuttles.
              </Text>
              <Text
                className='paragraph-centered paragraph'
                css={{
                  width: '100%',
                  margin: '0 auto',
                  paddingTop: '1em',
                }}>
                Airport pickup and delivery services are optional.
              </Text>
            </div>
          </div>
        </BookingDetailsGrid>
      </BookNowGrid>
    </>
  );
}
