import React from 'react';
import { Helmet } from 'react-helmet';
import HeroImageContainer from '../../../components/styles/HeroImageContainer';
import { Text, Container, Image } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HeroImage from '../../../assets/south-river/SouthHero-web.jpg';
import ScrollWheel from '../../ScrollWheel';
import styled from 'styled-components';
import HorizontalRule from '../../styles/HorizontalRule';
import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';
import ImageOne from '../../../assets/south-river/south-cropped-web.jpg';

const RiverGridOne = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;

  @media only screen and (min-width: 1000px) {
    margin: 4em;
  }
`;

const RiverGridTwo = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  margin: 2em;
  margin-top: 4em;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 4em;
    gap: 4em;
  }
`;

const RiverDetailsDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  @media only screen and (min-width: 1000px) {
    margin: 0;
  }
`;

export default function SouthFork() {
  const TITLE = 'TVL | South Fork';

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1, transition: { duration: 1 } }}
        viewport={{ once: true }}>
        <HeroImageContainer>
          <img
            className='heroImage'
            src={HeroImage}
            alt='Fisherman on the bank.'
          />
          <Container className='heroVideoOverlay'>
            <Container
              css={{
                marginLeft: '-.5em',
                marginTop: '14em',
                padding: '0',
                '@xs': {
                  marginLeft: '1.5em',
                  marginTop: '26em',
                },
              }}>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#ffffff',
                    fontSize: '50px',
                    marginTop: '4em',
                    '@xs': {
                      fontSize: '70px',
                      marginTop: '0',
                    },
                  }}>
                  SOUTH FORK
                </Text>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1, transition: { duration: 2 } }}
                viewport={{ once: true }}>
                <Text
                  h1
                  css={{
                    color: '#F75A0E',
                    fontSize: '30px',
                    '@xs': {
                      fontSize: '40px',
                      width: '500px',
                    },
                  }}>
                  OVER 5,000 FISH PER MILE
                </Text>
              </motion.div>
            </Container>
          </Container>
        </HeroImageContainer>
      </motion.div>
      <div>
        <ScrollWheel />
      </div>
      <motion.div
        initial={{ opacity: 0, y: 150 }}
        whileInView={{ opacity: 1, y: 0, transition: { duration: 0.8 } }}
        viewport={{ once: true }}>
        <RiverGridOne>
          <Text
            className='headline-black'
            css={{
              fontSize: '20px',
              textAlign: 'center',
              maxWidth: '800px',
              margin: '0 auto',
              '@xs': {
                fontSize: '30px',
              },
            }}>
            If you could only fish one river the rest of your life, the South
            Fork of the Snake would be the river to select. With over 60 miles
            of water, there is, without fail, trout feeding everywhere. Among
            thirteen different access points, there is no problem getting to the
            fish. Miles of water equals an abundance of trout. The South Fork of
            the Snake boasts over 5,000 fish per mile!
          </Text>
        </RiverGridOne>
      </motion.div>

        <Image
          src={ImageOne}
          css={{
            maxWidth: '100%',
            margin: '2em 0 2em 0',
          }}
        />

      <RiverGridTwo>
        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              '@xs': {
                fontSize: '35px',
              },
            }}>
            Lots of Bugs, Lots of Fish
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            The South Fork may be the most prolific river in all the western
            United States. With fish numbers around 5000 per mile and
            invertebrate numbers in the 50,000 to 120,000 per square meter, this
            river is second to none. There is almost always some kind of hatch
            happening during the season.
          </Text>
        </RiverDetailsDiv>

        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              marginTop: '2em',
              '@xs': {
                fontSize: '35px',
                marginTop: '0',
              },
            }}>
            Big Fish
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            All this food turns many of these trout into trophy trout. In the
            past several years we have seen large trout increase dramatically.
            Fish at or above 20 inches are caught regularly and several 23, 24,
            and 25-inch fish are caught every season. A few near 30 inches show
            themselves from time to time. There are few places you can go to
            find fish of this size in these numbers.
          </Text>
        </RiverDetailsDiv>

        <RiverDetailsDiv>
          <Text
            className='headline'
            css={{
              fontSize: '30px',
              marginTop: '2em',
              '@xs': {
                fontSize: '35px',
                marginTop: '0',
              },
            }}>
            Beautiful Setting
          </Text>
          <HorizontalRule />
          <Text
            className='paragraph'
            css={{
              width: '100%',
              margin: '0 auto',
            }}>
            Like the fishing, the scenery on the South Fork is second to none.
            Beautiful mountains, canyons, and forests dominate the 60 miles of
            landscape. Eagles, moose, and other waterfowl are found in great
            numbers and add to the charm and beauty of the experience. We feel
            lucky every day we are able to float down this amazing river.
          </Text>
        </RiverDetailsDiv>
      </RiverGridTwo>
    </>
  );
}
