export const collapseItems = [
  {
    title: 'Book Now',
    link: '/book-now',
  },
  {
    title: 'Trip Details',
    link: '/trip-details',
  },
  {
    title: 'Cast & Blast',
    link: '/cast-&-blast',
  },
  {
    title: 'Timeline',
    link: '/timeline',
  },
  // {
  //   title: 'Travel Destinations',
  //   link: '/destinations',
  // },
  {
    title: 'Cabins',
    link: '/cabins',
  },
  {
    title: 'Cuisine',
    link: '/cuisine',
  },
  // {
  //   title: 'Virtual Tour',
  //   link: '/',
  // },
  {
    title: '',
    link: '',
  },
  {
    title: 'Teton River',
    link: '/teton',
  },
  {
    title: 'South Fork',
    link: '/south-fork',
  },
  {
    title: "Henry's Fork",
    link: '/henrys-fork',
  },
  {
    title: '',
    link: '',
  },
  // {
  //   title: 'Staff',
  //   link: '/staff',
  // },
  {
    title: 'Guides',
    link: '/guides',
  },
  {
    title: 'Gallery',
    link: '/gallery',
  },
  {
    title: 'History',
    link: '/history',
  },
  {
    title: 'Contact Us',
    link: '/contact',
  },
  {
    title: '',
    link: '',
  },
  {
    title: "Let M' Run",
    link: 'https://letmrundrift-boats.com/',
  },
  {
    title: 'Shop TVL',
    link: 'https://shop.tetonvalleylodge.com/',
  },
];
