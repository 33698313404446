import styled from 'styled-components';
import {
  compose,
  color,
  shadow,
  space,
  border,
  typography,
  layout,
} from 'styled-system';

const HeroImageContainer = styled.div`
  ${compose(color, shadow, space, border, typography, layout)}

  width: 100%;
  height: 85vh;
`;

export default HeroImageContainer;
