import React from 'react';
import CuisineHero from '../../assets/cuisine/cuisineHero.JPG';
import { Text, Image } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HorizontalRule from '../styles/HorizontalRule';
import HorizontalRuleFW from '../styles/HorizontalRuleFW';
import styled from 'styled-components';
import CuisineOne from '../../assets/cuisine/CuisineOne.JPG';
import CuisineTwo from '../../assets/cuisine/CuisineTwo.JPG';
import CuisineThree from '../../assets/cuisine/CuisineThree.JPG';
import CuisineFour from '../../assets/cuisine/CuisineFour.JPG';
import CuisineFive from '../../assets/cuisine/CuisineFive.JPG';
import CuisineSix from '../../assets/cuisine/CuisineSix.JPG';
import CuisineSeven from '../../assets/cuisine/CuisineSeven.JPG';
import CuisineEight from '../../assets/cuisine/CuisineEight.JPG';
import CuisineNine from '../../assets/cuisine/CuisineNine.JPG';

import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';

const CuisineTopGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 70% 30%;
    grid-template-rows: 1fr min-content;
  }
`;

const CuisineLayeredGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: flex;
  flex-direction: column;
  grid-column: 1/2;
  padding: 1em;

  @media only screen and (min-width: 1000px) {
    grid-column: 2/3;
    grid-row: 1/2;
    padding: 0;
    padding-right: 4em;
    align-self: center;
  }
`;

const CuisineMiddleGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 1em;

  @media only screen and (min-width: 1000px) {
    padding: 4em;
  }
`;

const CuisineImagesGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  padding: 2em 1em 1em 1em;
  align-items: center;
  justify-items: center;

  @media only screen and (min-width: 1000px) {
    width: 90%;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 2em;
    gap: 2em;
    margin: 0 auto;
  }
`;

export default function Cuisine() {
  return (
    <>
      <CuisineTopGrid>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 2 } }}
          viewport={{ once: true }}>
          <img
            className='left-hero-image'
            src={CuisineHero}
            alt='Teton Valley Lodge dinner area.'
          />
        </motion.div>

        <CuisineLayeredGrid>
          <motion.div
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
            viewport={{ once: true }}>
            <Text
              className='headline'
              css={{
                fontSize: '30px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              Cuisine
            </Text>
            <HorizontalRule className='horizontal-small' />
            <Text
              className='paragraph'
              css={{
                fontSize: '20px',
                '@xs': {
                  fontSize: '30px',
                },
              }}>
              At Teton Valley Lodge, guests can indulge in a delightful 4-course
              meal that showcases the finest local ingredients and satisfies
              even the most discerning palates.
            </Text>
          </motion.div>
        </CuisineLayeredGrid>
      </CuisineTopGrid>
      <HorizontalRuleFW />
      <CuisineMiddleGrid>
        <div className='history-middle-section'>
          <Text
            className='headline-centered'
            css={{
              fontSize: '30px',
              '@xs': {
                fontSize: '35px',
              },
            }}>
            Savory 4-Course Dining with Local Flair
          </Text>
          <HorizontalRule className='hr-centered' />
          {/* <Text className='paragraph'>
              <Link
                className='destinations-link'
                href='/patagonia-accommodations'>
                Learn More
              </Link>
            </Text> */}
          <Text className='paragraph'>
            At Teton Valley Lodge, guests can indulge in a delightful 4-course
            meal that showcases the finest local ingredients and satisfies even
            the most discerning palates. The culinary journey begins with a
            tantalizing appetizer that sets the tone for the rest of the meal.
            From savory bruschetta topped with fresh tomatoes and herbs to
            crispy vegetable fritters served with a tangy dipping sauce, the
            appetizer course offers a burst of flavors and textures that awaken
            the taste buds.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            Moving on to the salad or soup course, guests can enjoy a refreshing
            and light dish that complements the serene ambiance of the lodge. A
            vibrant salad made with locally sourced greens, crisp vegetables,
            and a zesty vinaigrette dressing is a popular choice. Alternatively,
            a comforting bowl of homemade soup, such as creamy tomato bisque or
            hearty vegetable minestrone, warms the soul and prepares guests for
            the main course.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            For the main course, the lodge offers a selection of expertly cooked
            meats sourced from local ranches. Guests can savor tender cuts of
            beef, succulent chicken, or flavorful game, all prepared to
            perfection. Each dish is accompanied by seasonal vegetables and a
            rich, savory sauce that enhances the flavors and creates a
            harmonious blend of tastes.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            To conclude the meal on a sweet note, the lodge presents a tempting
            dessert that showcases the region's bountiful harvest. From decadent
            chocolate mousse to fruity berry tarts, the dessert course is a
            delightful finale. Paired with a cup of freshly brewed coffee or a
            glass of dessert wine, the dessert course provides the perfect
            ending to a memorable dining experience.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            Throughout the meal, guests can enjoy a selection of fine wines and
            craft beers that have been carefully chosen to complement the
            flavors of each course. The lodge's knowledgeable staff is always on
            hand to provide recommendations and ensure that every aspect of the
            dining experience is exceptional.
          </Text>
        </div>
      </CuisineMiddleGrid>
      <CuisineImagesGrid>
        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineOne}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineTwo}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineThree}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineFour}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineFive}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineSix}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineSeven}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineEight}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 150 }}
          whileInView={{ opacity: 1, y: 0, transition: { duration: 1 } }}
          viewport={{ once: true }}>
          <Image
            src={CuisineNine}
            css={{
              maxWidth: '100%',
              '@xs': {
                maxWidth: '600px',
              },
            }}
          />
        </motion.div>
      </CuisineImagesGrid>
    </>
  );
}
