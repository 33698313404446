import React from 'react';
import HistoryHero from '../../assets/history/HistoryHero-web.jpg';
import { Text } from '@nextui-org/react';
import { motion } from 'framer-motion';
import HorizontalRule from '../styles/HorizontalRule';
import HorizontalRuleFW from '../styles/HorizontalRuleFW';
import styled from 'styled-components';

import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';

const HistoryTopGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 1000px) {
    grid-template-columns: 70% 30%;
    grid-template-rows: 1fr min-content;
  }
`;

const HistoryLayeredGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: flex;
  flex-direction: column;
  grid-column: 1/2;
  padding: 1em;

  @media only screen and (min-width: 1000px) {
    grid-column: 2/3;
    grid-row: 1/2;
    padding: 0;
    padding-right: 4em;
    align-self: center;
  }
`;

const HistoryMiddleGrid = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  padding: 1em;

  @media only screen and (min-width: 1000px) {
    padding: 4em;
  }
`;

// const HistoryBottomGrid = styled.div`
//   ${compose(color, space, border, typography, layout, grid)}

//   display: grid;
//   grid-template-columns: 1fr;
//   gap: 1em;
//   padding: 0 1em 0 1em;
//   align-items: center;
//   justify-items: center;

//   @media only screen and (min-width: 1000px) {
//     grid-template-columns: 1fr 2fr 1fr;
//     padding: 4em;
//     gap: 4em;
//   }
// `;

export default function History() {
  return (
    <>
      <HistoryTopGrid>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 2 } }}
          viewport={{ once: true }}>
          <img
            className='left-hero-image'
            src={HistoryHero}
            alt='Teton Valley Lodge Raft with fishermen.'
          />
        </motion.div>

        <HistoryLayeredGrid>
          <motion.div
            initial={{ opacity: 0, y: 150 }}
            whileInView={{ opacity: 1, y: 0, transition: { duration: 1.1 } }}
            viewport={{ once: true }}>
            <Text
              className='headline'
              css={{
                fontSize: '30px',
                '@xs': {
                  fontSize: '60px',
                },
              }}>
              History
            </Text>
            <HorizontalRule className='horizontal-small' />
            <Text
              className='paragraph'
              css={{
                fontSize: '20px',
                '@xs': {
                  fontSize: '30px',
                },
              }}>
              Teton Valley Lodge is a place where many people make lasting
              memories. For us to help others create these memories, we
              need to remember where we came from. We must continue a legacy.
            </Text>
          </motion.div>
        </HistoryLayeredGrid>
      </HistoryTopGrid>
      <HorizontalRuleFW />
      <HistoryMiddleGrid>
        <div className='history-middle-section'>
          <Text
            className='headline-centered'
            css={{
              fontSize: '30px',
              '@xs': {
                fontSize: '35px',
              },
            }}>
            Our Story
          </Text>
          <HorizontalRule className='hr-centered' />
          {/* <Text className='paragraph'>
              <Link
                className='destinations-link'
                href='/patagonia-accommodations'>
                Learn More
              </Link>
            </Text> */}
          <Text className='paragraph'>
            In the early 1900's Teton Valley was a remote community. Settlers
            came here for the beauty and opportunity to build something that
            others were skeptical to try. The Kunz family was one of these
            settlers who came here to build a life. Alma Kunz was born in 1900
            up the road from where the lodge is located today.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            In 1912 the isolation in the valley began to disappear. The railroad
            changed everything. Travelers now came through Teton Valley as they
            came to visit Yellowstone and Grand Teton National Parks. In 1919
            Alma decided to put his extensive fishing and hunting experience to
            work guiding these tourists passing through the valley.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            In 1938 Alma took a leap of faith and purchased land on the river
            and built the lodge. Through the years his reputation spread
            throughout the country. Anglers traveled from near and far to fish
            the Teton River with the master.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            In the 1950s Randy Berry and John Pehrson came to work for Alma as
            guides. They each started at the young age of 12. Each of them
            guided for Alma through high school and then went off to serve the
            country. When they returned Alma had passed away and the lodge was
            owned are run by Alma's son Lyle. John and Randy began guiding on
            their own out of Driggs. Guiding all summer and tying flies all
            winter was their entire life.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            In 1973 Randy and John were able to buy the lodge and begin their
            roles as the head of the organization. For the next 40 years, these
            two pioneered modern outfitting and guiding flyfishing in the West.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            In 2001 Brian Berry was able to buy into the lodge ownership as John
            stepped away from the business. Brian learned from John and Randy
            from the time he was young. He saw the way they worked and fished.
            How they valued relationships with customers. The way they trained
            guides and grew the business.
          </Text>

          <Text
            className='paragraph'
            css={{
              marginTop: '2em',
              '@xs': {
                marginTop: '1em',
              },
            }}>
            Today Brian and Joselle stand at the head of the business that was
            built over the past 105 years. Their main goal is to continue the
            legacy and standard set by Alma many years ago.
          </Text>
        </div>
      </HistoryMiddleGrid>
      {/* <HorizontalRuleFW /> */}
      {/* <HistoryBottomGrid>
        <Text
          className='paragraph'
          css={{
            marginTop: '0em',
            '@xs': {
              marginTop: '0em',
            },
          }}>
          In 2019, we celebrated 100 years of outfitting. Guiding and making
          memories with our clients has been a magnificent experience. We remain
          dedicated to making Teton Valley Lodge the premier flyfishing lodge.
        </Text>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 2 } }}
          viewport={{ once: true }}>
          <img
            className='history-hundred-years'
            src={HundredYears}
            alt='100 Years of Outfitting Logo'
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 2 } }}
          viewport={{ once: true }}>
          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/uHySrFlywDc?si=P58_x8NtoEtZHeTl'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen></iframe>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1, transition: { duration: 2 } }}
          viewport={{ once: true }}>
          <img
            className='history-marilyn'
            src={Marilyn}
            alt='Marilyn Monroe Teton Valley Lodge'
          />
        </motion.div>
      </HistoryBottomGrid> */}
    </>
  );
}
