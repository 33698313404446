import React from 'react';
import styled from 'styled-components';
import { Image, Text } from '@nextui-org/react';
import Logo from '../assets/tvl_logo_bw.png';
import '../components/styles/Footer.css';
import { FaInstagram, FaFacebook } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import {
  compose,
  color,
  space,
  border,
  typography,
  layout,
  grid,
} from 'styled-system';

const FooterWrapper = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  width: 100%;
  height: 100%
  display: grid;
  margin-top: 0;
  grid-template-rows: 1fr;
`;

const FooterHr = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  border: 1px solid #F75A0E;
  width: 30px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
`;

const LinkContainer = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: flex;
  flex-direction: column;
  justify-self: left;
  margin-top: 2em;
  margin-bottom: 2em;
  line-height: 1.5;
  font-size: 18px;
  text-align: left;
  color: #ffffff;

  @media only screen and (min-width: 700px) {
    justify-self: center;
  }
`;

const FooterGridTop = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  background-color: #506a63;
  padding: 2em;

  @media only screen and (min-width: 700px) {
    grid-template-rows: 1fr 100px 50px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 2em 4em 2em 4em;
  }

  @media only screen and (min-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

const FooterSocialDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  display: flex;
  flex-direction: row;
  margin-top: 0.5em;
  gap: 1em;
`;

const FooterLogoDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  grid-column: 1;
  align-self: end;

  @media only screen and (min-width: 700px) {
    grid-column: 2/4;
    grid-row: 2;
  }
`;

const FooterCopyrightDiv = styled.div`
  ${compose(color, space, border, typography, layout, grid)}

  grid-column: 1;
  align-self: end;
  justify-self: center;
  margin-top: 2em;

  @media only screen and (min-width: 700px) {
    grid-column: 2/4;
    grid-row: 3;
  }
`;

export default function Footer() {
  const current = new Date();
  const date = current.getFullYear();

  return (
    <FooterWrapper>
      <FooterGridTop>
        <LinkContainer>
          <Text h1 css={{ fontSize: '25px', color: '#FFFFFF' }}>
            Drop Us A Line
          </Text>
          <FooterHr />
          <Text css={{ fontSize: '18px', color: '#FFFFFF' }}>
            +1 208-354-2386
          </Text>
          <Link
            to='mailto:flyfish@tetonvalleylodge.com'
            className='footerLink'
            css={{ fontSize: '18px' }}>
            flyfish@tetonvalleylodge.com
          </Link>
        </LinkContainer>
        <LinkContainer>
          <Text h1 css={{ fontSize: '25px', color: '#FFFFFF' }}>
            Visit Us
          </Text>
          <FooterHr />
          <Text css={{ fontSize: '18px', color: '#FFFFFF' }}>
            3733 Adams Rd
            <br />
            Driggs, ID 83422
          </Text>
        </LinkContainer>
        <LinkContainer>
          <Text h1 css={{ fontSize: '25px', color: '#FFFFFF' }}>
            Follow Us
          </Text>
          <FooterHr />
          <FooterSocialDiv>
            <a
              className='footerLink'
              rel='noreferrer'
              href='https://www.instagram.com/tetonvalleylodge/'
              target='_blank'>
              <FaInstagram fontSize={23} />
            </a>

            <a
              className='footerLink'
              rel='noreferrer'
              href='https://www.facebook.com/tetonvalleylodge/'
              target='_blank'>
              <FaFacebook fontSize={23} />
            </a>
          </FooterSocialDiv>
        </LinkContainer>
        <LinkContainer>
          <Text h1 css={{ fontSize: '25px', color: '#FFFFFF' }}>
            Quick Links
          </Text>
          <FooterHr />
          <Link
            className='footerLink'
            to='/book-now'
            css={{ fontSize: '18px' }}>
            Book Now
          </Link>
          <Link
            className='footerLink'
            to='/trip-details'
            css={{ fontSize: '18px' }}>
            Trip Details
          </Link>
          <Link
            className='footerLink'
            to='/trip-details'
            css={{ fontSize: '18px' }}>
            Contact Us
          </Link>
        </LinkContainer>

        <FooterLogoDiv>
          <Link to='/'>
            <Image width='125px' src={Logo} alt='Black and White Fish Logo' />
          </Link>
        </FooterLogoDiv>

        <FooterCopyrightDiv>
          <Text css={{ color: '#EAEAEA', fontSize: '14px' }}>© {date} Teton Valley Lodge</Text>
        </FooterCopyrightDiv>

        {/* <Text css={{ color: '#EAEAEA' }}>
          Website by{' '}
          <a
            rel='noreferrer'
            className='developerLink'
            href='https://austintjacobs.com/'
            target='_blank'>
            Austin Jacobs
          </a>
        </Text> */}
      </FooterGridTop>
    </FooterWrapper>
  );
}
